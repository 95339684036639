import { Button } from '@material-ui/core';
import React from 'react';
import example from '../../Assets/Examples/Mobile_listings.png';
import { styles } from '../../Styles/Misc/HowToStyles';

/* This component aims to hold the "how to" text above the listings management. */
export const HowToListings = ({ manage }) => (
  <div style={styles.main}>
    <div style={styles.secondaryContainer}>
      <div style={styles.imageContainer}>
        <div style={styles.imageWrapper}>
          <img alt="example.png" src={example} style={styles.image} />
        </div>
        <div style={styles.textContainer}>
          <h1>Manage your listings</h1>
          <p>
            These are your menu listings, they are ordered by category and can
            be updated or removed anytime you like.
          </p>
          <p>
            Each feature of this screen is available based on your role. For
            example, Admin's can do anything, Manager's can switch the order of
            your listings, and Employee's can only view the different items.
          </p>
          <p>
            To add a new listing or category, please click the blue dropdown and
            select "Add New Category" or "Add New Listing"
          </p>
          <p>
            To change the order of your items, please click and drag each item
            to the desired order! Items are ordered from top to bottom but your
            changes will only show up on the app when you press "Save" from the
            menu dropdown next to the category dropdown.
          </p>
          <Button color="primary" onClick={manage} variant="contained">
            Manage Categories
          </Button>
        </div>
      </div>
      <hr style={styles.divider} />
    </div>
  </div>
);
