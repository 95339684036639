import { Helmet } from 'react-helmet';
import { displayName } from '../../Api/FirebaseConfigHandler';
import Listings from '../../Assets/Images/Listings.svg';
import More from '../../Assets/Images/More.svg';
import Posts from '../../Assets/Images/Posts.svg';
import Users from '../../Assets/Images/Users.svg';
import { ActionCard, AnalyticsModule } from '../../Components/Dashboard';
import { Footer, Header, SideBar } from '../../Components/Misc';
import { getFormattedProjectName } from '../../Helpers/Utils';
import useAuth from '../../Hooks/useAuth';
import { colors } from '../../Styles/Colors';

export const Dashboard = ({ history }) => {
  const { user } = useAuth();
  const projectId = localStorage.getItem('appId');
  const LISTINGS = !!localStorage.getItem('LISTINGS');
  const POSTS = !!localStorage.getItem('POSTS');
  const USERS = !!localStorage.getItem('USERS');

  const helmetTitle = getFormattedProjectName();

  if (!user?.email) {
    const projectId = localStorage.getItem('appId');
    history.push({ pathname: `/login/${projectId}` });
  }

  return (
    <>
      <Helmet title={`${helmetTitle} | Dashboard`} />
      <div
        style={{
          backgroundColor: '#E8E8E8',
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Dashboard" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            minHeight: '100vh',
            width: '100%',
          }}
        >
          <Header history={history} projectId={projectId} screen="Dashboard" />

          <div
            style={{
              backgroundColor: colors.moss,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <h3 style={{ color: colors.white, height: 250, paddingLeft: 30 }}>
              {displayName ? displayName : 'Your Custom Portal'}
            </h3>

            {/* This div needs to center the content of the screen. */}
            <div
              style={{
                alignItems: 'center',
                backgroundColor: '#E8E8E8',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <AnalyticsModule history={history} />

              {/* Submodule for "Manage" */}

              <div
                style={{
                  backgroundColor: '#E8E8E8',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 20,
                }}
              >
                {USERS && (
                  <ActionCard
                    action={() =>
                      history.push({
                        pathname: `/users/${projectId}`,
                      })
                    }
                    backgroundColor={colors.aqua}
                    buttonTitle="Manage Users"
                    description="Know who is in your app!"
                    history={history}
                    image={Users}
                    subheader="-"
                    textColor={colors.white}
                    title="Manage Users"
                  />
                )}
                {LISTINGS && (
                  <ActionCard
                    action={() =>
                      history.push({
                        pathname: `/listings/${projectId}`,
                      })
                    }
                    backgroundColor={colors.moss}
                    buttonTitle="Manage Listings"
                    description="Click to take control of your data."
                    history={history}
                    image={Listings}
                    subheader="-"
                    textColor={colors.white}
                    title="Manage Listings"
                  />
                )}
              </div>
              <div
                style={{
                  backgroundColor: '#E8E8E8',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 200,
                }}
              >
                {POSTS && (
                  <ActionCard
                    action={() =>
                      history.push({
                        pathname: `/posts/${projectId}`,
                      })
                    }
                    backgroundColor={colors.red}
                    buttonTitle="Manage Posts"
                    description="Click to engage with your users."
                    history={history}
                    image={Posts}
                    subheader="-"
                    textColor={colors.white}
                    title="Manage Posts"
                  />
                )}

                <ActionCard
                  action={() =>
                    history.push({
                      pathname: `/more/${projectId}`,
                    })
                  }
                  backgroundColor={colors.deepblack}
                  buttonTitle="View More"
                  description="View your terms and conditions, privacy policy, and more."
                  history={history}
                  image={More}
                  subheader="-"
                  textColor={colors.white}
                  title="View More"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
