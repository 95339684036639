import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
// Import components
import {
  HowToListings,
  ListingsOrganizer,
  ListingTableHeader,
  NoListingsText,
} from '../../Components/Listings';
import {
  GenericSnackbar,
  Header,
  ImageModal,
  SideBar,
} from '../../Components/Misc';
import {
  getAllCategories,
  getAllListings,
  updateAllListings,
} from '../../Helpers/Data';
import { getFormattedProjectName } from '../../Helpers/Utils';

// import useAuth from "../../Hooks/useAuth";

/* Display all listings for the company. */
export const AllListings = ({ history }) => {
  let projectId = localStorage.getItem('appId');
  const [categories, setCategories] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [listings, setListings] = useState([]);
  const [message, setMessage] = useState(
    `Hello! You can organize your listings by dragging and dropping them in the stack! The listings toward the top will show up first in the app. To save these changes so they reflect on the mobile app, please click "save" in the bottom right-hand corner.`
  );
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showFeaturedItems, setShowFeatured] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [snackbar, setShowSnackbar] = useState(false);
  const helmetTitle = getFormattedProjectName();

  /* Used to call our getlistings function and save to state. */
  const callGetListings = async () => {
    const temp = [];
    const cats = await getAllCategories();
    const items = await getAllListings();

    for (let i = 0; i < items.length; i++) {
      if (items[i].category === cats[0]) {
        temp.push(items[i]);
      }
    }

    setCategories(cats);
    setFilteredItems(temp);
    setListings(items);
    setSelectedCategory(cats[0]);
  };

  /* Get our list of listings as fast as we can. */
  useEffect(() => {
    callGetListings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Helper for closing all modals on the screen. */
  const closeModals = () => {
    setShowModal(false);
    setShowSnackbar(false);
  };

  /* Show modal to blow up image with onclick. */
  const enhanceImage = (imgUrl) => {
    setImageUrl(imgUrl);
    setShowModal(true);
  };

  /* Change the selected category. */
  const handleCategoryChange = (category) => {
    let temp = [];
    for (let i = 0; i < listings.length; i++) {
      if (listings[i].category === category) {
        temp.push(listings[i]);
      }
    }

    setFilteredItems(temp);
    setSelectedCategory(category);
  };

  /* Check to see if the authenticated user can access the listings. */
  // const isDisabled = (button) => {
  //   if (user.role === "Admin") {
  //     // Admin's can do whatever.
  //     return false;
  //   } else if (user.role === "Manager" && button === "SAVE") {
  //     // Manager's can switch the ordering.
  //     return false;
  //   } else {
  //     // If anything else, do not access.
  //     return true;
  //   }
  // };

  /* Navigate the user to the category management screen.*/
  const navigateToCategoryManagement = () => {
    projectId = localStorage.getItem('appId');
    history.push({ pathname: `/manage-categories/${projectId}` });
  };

  /* Call firebase so we get up-to-date data. */
  const reloadData = () => {
    // Wrap this function call in a state change to show loading if we want to.
    callGetListings();
  };

  /* Save listings in firestore with the appropriate ordering. */
  const saveListings = async () => {
    try {
      const saved = await updateAllListings(filteredItems.length > 0 ? filteredItems : listings);
      saved === 'SUCCESS' ? showChangesSaved() : showError(saved);
    } catch (err) {
      console.error(err);
    }
  };

  const updateListings = (newList) => {
    if (filteredItems.length > 0) {
      setFilteredItems(newList)
    } else {
      setListings(newList);
    }
  }

  /* Show the add new listing modal here. */
  const sendToAddModal = () => {
    projectId = localStorage.getItem('appId');
    history.push({
      pathname: `/add-listing/${projectId}`,
      category: selectedCategory,
    });
  };

  /* Given an array from our search bar, set the filterUsers array in state. */
  const setSearch = (filteredList) => {
    setFilteredItems(filteredList);
  };

  /* Save the order in firebase. */
  const showChangesSaved = () => {
    setMessage('Your changes have been saved!');
    setShowSnackbar(true);
  };

  /* Show an error modal with the message of what happened. */
  const showError = (errorMessage) => {
    showSnackbar(errorMessage);
  };

  /* Helper to set the state so our snackbar will show up. */
  const showSnackbar = (msg) => {
    if (!msg)
      msg = `Hello! You can organize your listings by dragging and dropping them in the stack! The listings toward the top will show up first in the app. To save these changes so they reflect on the mobile app, please click "save" in the bottom right-hand corner.`;
    setMessage(msg);
    setShowSnackbar(true);
  };

  /* Toggle between only featured items and all listings. */
  const toggleFeatured = (category, items) => {
    let temp = [];

    if (!showFeaturedItems) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].featured && items[i].category === category) {
          temp.push(items[i]);
        }
      }
    } else {
      for (let i = 0; i < items.length; i++) {
        if (items[i].category === category) {
          temp.push(items[i]);
        }
      }
    }

    setFilteredItems(temp);
    setShowFeatured(!showFeaturedItems);
  };

  /* Show a snackbar if the featured change was successful or not. */
  const showFeaturedUpdateMessage = (updated, listing) => {
    let msg = updated
      ? 'Listing successfully featured!'
      : 'Something went wrong, please try again.';

    if (!listing.featured) {
      msg = 'Listing successfully unfeatured!';
    }

    let items = filteredItems.length > 0 ? filteredItems : listings;

    for (let i = 0; i < items.length; i++) {
      if (items[i]._id === listing._id) {
        items[i] = listing;
      }
    }

    setListings(items);
    setMessage(msg);
    setShowSnackbar(true);
  };

  return (
    <>
      <Helmet title={`${helmetTitle} | Listings`} />
      <div
        style={{
          backgroundColor: '#e8e8e8',
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Listings" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Header
            history={history}
            projectId={projectId}
            screen="Manage My Listings"
          />

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <HowToListings manage={navigateToCategoryManagement} />

            <div
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: 900,
                width: '85%',
              }}
            >
              {categories && categories.length > 0 && (
                <ListingTableHeader
                  categories={categories}
                  changeCategory={handleCategoryChange}
                  listings={listings}
                  reloadCategories={reloadData}
                  saveListings={saveListings}
                  sendToAddModal={sendToAddModal}
                  setSearch={setSearch}
                  showFeatured={showFeaturedItems}
                  showSnackbar={showSnackbar}
                  toggleFeatured={toggleFeatured}
                />
              )}

              <div
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: 900,
                  width: '100%',
                }}
              >
                {filteredItems && filteredItems.length > 0 && (
                  <ListingsOrganizer
                    enhanceImage={enhanceImage}
                    history={history}
                    list={filteredItems.length > 0 ? filteredItems : listings}
                    projectId={projectId}
                    showSnackbar={showFeaturedUpdateMessage}
                    updateListings={updateListings}
                  />
                )}
                {!filteredItems && <NoListingsText />}
                {filteredItems && filteredItems.length < 1 && (
                  <NoListingsText />
                )}
              </div>
            </div>

            {/* Show conditionally rendered comps here */}
            {showModal && (
              <ImageModal
                close={closeModals}
                imgUrl={imageUrl}
                open={showModal}
              />
            )}
            {snackbar && (
              <GenericSnackbar
                close={closeModals}
                message={message}
                open={showSnackbar}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
