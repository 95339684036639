import { Button } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
// Import Components
import {
  GenericAddButton,
  GenericSnackbar,
  Header,
  SideBar,
} from '../../Components/Misc';
import { ConfirmDeleteModal } from '../../Components/Modals';
import { EditTeammateForm } from '../../Components/Team';
import { editTeammember, removeTeammember } from '../../Helpers/Data';

export const EditTeamMember = ({ history, location }) => {
  const [formState, setFormState] = useState(null);
  const [message, setMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const projectId = localStorage.getItem('appId');
  const user = location.state;

  /* Show some loading UI while we edit the document in firebase storage. */
  const showLoadingProcess = (state) => {
    setFormState(state);
    setSubmitting(true);
  };

  /* Send the user to the previous screen. */
  const navigateBack = useCallback(() => {
    history.push({ pathname: `/team/${projectId}` });
  }, [history, projectId]);

  /* Initiate the redirect timer, used for when operation is successful and user needs to go back. */
  const initRedirectTimer = useCallback(() => {
    setTimeout(() => {
      navigateBack();
    }, 3000);
  }, [navigateBack]);

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = useCallback(
    (msg, redirect) => {
      setMessage(msg);
      setSnackbar(true);
      if (redirect) {
        initRedirectTimer();
      }
    },
    [initRedirectTimer]
  );

  /* Find the document in Firestore and edit the appropriate fields. */
  const callEditTeammate = useCallback(async () => {
    // Parse through what's changed and what hasn't changed and save to the user object.
    const stateUser = formState;

    user.bio = stateUser.description ? stateUser.bio : stateUser.bio;
    user.imageName = stateUser.imageName ? stateUser.imageName : user.imageName;
    user.name = stateUser.name ? stateUser.name : user.name;
    user.url = stateUser.url ? stateUser.url : user.url;

    const edited = await editTeammember(user);
    const shouldShow = edited === 'SUCCESS';
    const msg = shouldShow ? `${user.name} has been updated!` : edited;
    showSnackbar(msg, shouldShow);
    setSubmitting(false);
  }, [formState, showSnackbar, user]);

  useEffect(() => {
    if (submitting) callEditTeammate();
  }, [callEditTeammate, submitting]);

  /* Close all modals. */
  const closeModals = () => {
    setShowDeleteModal(false);
    setSnackbar(false);
  };

  /* Call our removeTeammember function in Data.js */
  const callRemoveUser = useCallback(async () => {
    const userId = user._id;
    const removed = await removeTeammember(userId);

    const shouldShow = removed === 'SUCCESS';
    const msg = shouldShow
      ? 'Your teammate has been successfully deleted.'
      : removed;
    setShowDeleteModal(false);
    showSnackbar(msg, shouldShow);
  }, [showSnackbar, user._id]);

  /* Show modal that asks the user to confirm deleting a teammate. */
  const showConfirmDeleteModal = () => {
    setShowDeleteModal(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <SideBar history={history} projectId={projectId} screen="Team" />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Header
          history={history}
          projectId={projectId}
          screen="Manage My Team"
        />

        <div
          style={{
            alignItems: 'center',
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            maxWidth: 600,
            width: '100%',
            marginTop: 100,
          }}
        >
          <div style={{ marginBottom: 30, paddingLeft: 120, width: '100%' }}>
            <Button
              color="primary"
              onClick={history.goBack}
              variant="contained"
            >
              Back
            </Button>
          </div>

          <EditTeammateForm
            confirmDelete={showConfirmDeleteModal}
            error={showSnackbar}
            teammate={user}
            submit={showLoadingProcess}
            submitting={submitting}
          />

          {/* Show a back button in the top left corner of the page. */}
          <div style={{ position: 'fixed', left: 100, top: 80 }}>
            <GenericAddButton action={navigateBack} screen="team" text="BACK" />
          </div>
          {/* Show modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
          {showDeleteModal && (
            <ConfirmDeleteModal
              category="teammate"
              close={closeModals}
              submit={callRemoveUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};
