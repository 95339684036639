import example from '../../Assets/Examples/MessageExample.png';
import './styles.css';

export const FacebookMessaging = () => {
  /* Refresh the messaging widget. */
  const updateFacebook = () => {
    if (
      window.location.pathname.includes('facebook') &&
      window.FB &&
      window.FB.CustomerChat
    ) {
      let siteCode = window.location.search;

      if (siteCode.length === 0) {
        siteCode = window.location.pathname.split('$')[1];
        siteCode = '?' + siteCode;
      }

      siteCode = siteCode === '?undefined' ? '?HGrille' : siteCode;

      let pageId = 777235222402643;
      switch (siteCode) {
        case '?HGrille':
          pageId = 247706520233;
          break;
        case '?Authoritek-Testing':
          pageId = 777235222402643;
          break;
        case '?317-Coffee':
          pageId = 297456927514336;
          break;
        case '?BillingPortal':
          pageId = 777235222402643;
          break;
        case '?Saugatuck-Schools':
          pageId = 777235222402643;
          break;
        case '?Frankenmuth-Schools':
          pageId = 777235222402643;
          break;
        case '?Flamingo-Consulting':
          pageId = 107499477532770;
          break;
        case '?Man-Cave':
          pageId = 116508275537452;
          break;
        case '?Random':
          pageId = 112889354503812;
          break;
        case '?Townline-Hatchery':
          pageId = 453952121376766;
          break;
        default:
          pageId = 777235222402643;
          break;
      }

      // Set the new pageId and refresh the facebook chat plugin.
      document
        .getElementsByClassName('fb-customerchat')[0]
        .setAttribute('page_id', pageId);
      window.FB.XFBML.parse(document.getElementById('chatdiv'));
    }
  };

  if (window.FB && window.FB.CustomerChat) {
    updateFacebook();
    window.FB.CustomerChat.show();
  }

  return (
    <div className="facebook-container">
      <div className="facebook-image-wrapper">
        <img
          src={example}
          alt="example.png"
          className="facebook-messaging-image"
        />
        <strong>Have a question? Click the button below to ask us!</strong>
      </div>
    </div>
  );
};
