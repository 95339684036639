import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { useState } from 'react';
import { toggleCategory } from '../../Helpers/Data';
import { renderDate } from '../../Helpers/Generators';
import { styles } from '../../Styles/Categories/CategoriesTable';
import { GenericSnackbar } from '../Misc/GenericSnackbar';

export const CategoriesTable = ({ categories, showModal }) => {
  const [message, setMessage] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRows] = useState(5);
  const [snackbar, setSnackbar] = useState(false);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    { id: 'hidden', numeric: false, disablePadding: false, label: 'Hidden' },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Created At',
    },
  ];

  /* Sort the data by the given orderBy comparator. */
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  /* Based on the order value, sort the data in descending or ascending order. */
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  /* Change the page number we're on. */
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  /* Change the amount of rows per page we're on. */
  const handleChangeRowsPerPage = (event) => {
    setRows(event.target.value);
  };

  /* Display an edit modal from the parent component. */
  const handleNavigation = (category) => {
    showModal(category);
  };

  /* Close any overlapping ui. */
  const closeModals = () => {
    setMessage('');
    setSnackbar(false);
  };

  /* Render the appropriate date given a Unix timestamp. */
  const callRenderDate = (seconds) => {
    return renderDate(seconds);
  };

  /* Helper for sorting our user data. */
  const sortHandler = (sortId) => {
    handleRequestSort('', sortId);
  };

  /* Sort handler checks if we are ordering by asc/desc and sets the state to the inputted id. */
  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /* Sort a row based on the comparitor inputted. */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  /* Toggle the hidden property for a given category. */
  const swapHidden = async (_id, hidden) => {
    const updated = await toggleCategory(_id, !hidden);

    if (updated === 'SUCCESS') {
      return true;
    } else {
      setMessage(updated);
      setSnackbar(true);
    }
  };

  return (
    <div style={styles.main}>
      <Paper elevation={3} style={styles.container}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      style={styles.tableRowHeader}
                      onClick={() => sortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="left" style={styles.tableRowHeader}>
                  Manage
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(categories, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((cat, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={cat?._id}
                    style={
                      index % 2
                        ? { backgroundColor: '#F7F7F7' }
                        : { backgroundColor: '#FFF' }
                    }
                  >
                    <TableCell
                      align="left"
                      component="th"
                      id={cat._id}
                      scope="row"
                      padding="none"
                    >
                      {cat.name}
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        checked={cat.hidden}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={() => swapHidden(cat._id, cat.hidden)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {callRenderDate(cat.createdAt.seconds)}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleNavigation(cat)}
                      >
                        Manage
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={categories.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Show conditionally rendered comps heree */}
      {snackbar && (
        <GenericSnackbar
          close={closeModals}
          message={message}
          open={snackbar}
        />
      )}
    </div>
  );
};
