import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

export const GenericSnackbar = ({ close, message, open }) => (
  <Snackbar
    action={
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={close}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    }
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    message={message}
    onClose={close}
    open={open}
    style={{ maxWidth: 300 }}
  />
);
