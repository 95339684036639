import { Box, LinearProgress, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// Import components
import {
  CustomButton,
  CustomTextField,
} from '../../Components/Custom/CustomForm';

export const AddPostForm = ({ submit, submitting }) => (
  <Paper
    elevation={3}
    style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 400,
      padding: 40,
    }}
  >
    <h3 style={{ textAlign: 'center' }}>Create A New Post</h3>
    <Formik
      initialValues={{
        body: '',
        title: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        body: Yup.string().required('Please enter a message for this post.'),
        title: Yup.string().required('Please enter a title for this post.'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setSubmitting(true);
          submit(values);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          console.error('CAUGHT ERROR:', err);

          setStatus({ success: false });
          setErrors({ submit: err });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <CustomTextField
            error={Boolean(touched.title && errors.title)}
            name="title"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Title"
            style={{ marginBottom: 20, width: '100%' }}
            value={values.title}
            variant="outlined"
          />
          <CustomTextField
            error={Boolean(touched.body && errors.body)}
            multiline
            name="body"
            placeholder="Description"
            onBlur={handleBlur}
            onChange={handleChange}
            rows={8}
            style={{ marginBottom: 20, width: '100%' }}
            value={values.body}
            variant="outlined"
          />
          <hr style={{ width: '100%' }} />
          {submitting && (
            <LinearProgress
              color="primary"
              style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
            />
          )}
          <CustomButton
            color="primary"
            disabled={submitting || isSubmitting}
            type="submit"
            variant="contained"
          >
            SUBMIT
          </CustomButton>
          <Box style={{ marginTop: 10 }}>
            {errors.submit && (
              <Alert severity="error">
                <div>{errors.submit}</div>
              </Alert>
            )}
          </Box>
        </form>
      )}
    </Formik>
  </Paper>
);

AddPostForm.propTypes = {
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};
