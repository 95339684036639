import React from 'react';
import { Helmet } from 'react-helmet';
import { parentSite } from '../../Api/FirebaseConfigHandler';
import AndroidImage from '../../Assets/Images/AndroidImage.png';
import IosImage from '../../Assets/Images/IosImage.png';
import { Header, SideBar } from '../../Components/Misc';
import { getFirebaseUrl } from '../../Config';
import { getFormattedProjectName } from '../../Helpers/Utils';
import useAuth from '../../Hooks/useAuth';
import { styles } from '../../Styles/More';

const projectId = getFirebaseUrl();

/* This just basically shows some legal links, link to their home website, and the billing portal. */
export const More = ({ history }) => {
  const { user } = useAuth();
  const ANDROID_LINK = localStorage.getItem('ANDROID_LINK');
  const IOS_LINK = localStorage.getItem('IOS_LINK');
  const helmetTitle = getFormattedProjectName();

  if (!user?.email) {
    const projectId = localStorage.getItem('appId');
    history.push({ pathname: `/login/${projectId}` });
  }

  return (
    <>
      <Helmet title={`${helmetTitle} | More`} />
      <div style={styles.main}>
        <SideBar history={history} projectId={projectId} screen="More" />
        <div style={styles.secondary}>
          <Header history={history} projectId={projectId} screen="Info" />
          <div style={styles.linkscontainer}>
            {/* Terms link */}
            <div>
              <a
                style={styles.link}
                href="https://www.authoritek.com/terms-of-use"
              >
                <h1 style={styles.linktitle}>Terms And Conditions</h1>
              </a>
            </div>
            {/* Privacy policy */}
            <div>
              <a
                style={styles.link}
                href="https://www.authoritek.com/privacy-policy"
              >
                <h1 style={styles.linktitle}>Privacy Policy</h1>
              </a>
            </div>
            {/* Billing Portal */}
            {/* <div>
            <a style={styles.link} href="https://portal.authoritek.com/login">
              <h1 style={styles.linktitle}>Billing Portal</h1>
            </a>
          </div> */}
            {/* Parent site */}
            <div>
              <a style={styles.link} href={parentSite}>
                <h1 style={styles.linktitle}>Parent Site</h1>
              </a>
            </div>
            {/* Help link */}
            <div>
              <a style={styles.link} href="https://www.authoritek.com/help">
                <h1 style={styles.linktitle}>Help</h1>
              </a>
            </div>
            <div style={styles.applinkscontainer}>
              <img
                alt="ios.png"
                onClick={() => {
                  window.open(IOS_LINK, '_blank');
                }}
                src={IosImage}
                style={styles.iosimage}
              />
              <img
                alt="android.png"
                onClick={() => {
                  window.open(ANDROID_LINK, '_blank');
                }}
                src={AndroidImage}
                style={styles.androidimage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
