import Button from '@material-ui/core/Button';
import React from 'react';

/* Generic button for doing things within the webapp. */
export const GenericAddButton = ({ action, disabled, screen, text }) => (
  <Button
    color="primary"
    disabled={disabled}
    onClick={() => action(screen)}
    variant="contained"
  >
    {text}
  </Button>
);
