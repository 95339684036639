// Material UI custom stuff
import { Button, Select, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { colors } from '../../Styles/Colors';

/*
 * This file contains our custom material-ui components.
 * If we add more down the road, please throw them in here.
 */

// Light textfield to stand out against a dark background.
export const CustomTextField = withStyles({
  root: {
    backgroundColor: colors.white,
    borderRadius: 5,
  },
})(TextField);

export const CustomAutoComplete = withStyles({
  root: {
    color: colors.white,
  },
  label: {
    fontWeight: 'bold',
  },
})(Autocomplete);

// Tealish button with white text.
export const CustomButton = withStyles({
  root: {
    backgroundColor: colors.aqua,
    color: colors.white,
  },
  label: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
})(Button);

// Sand button with white text.
export const CustomButton2 = withStyles({
  root: {
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  label: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
})(Button);

// Tealish button with dark text.
export const CustomButtonDark = withStyles({
  root: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  label: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
})(Button);

// Sand button with dark text.
export const CustomButton2Dark = withStyles({
  root: {
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  label: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
})(Button);

// Light select component.
export const CustomSelect = withStyles({
  root: {
    backgroundColor: colors.white,
    borderRadius: 5,
    width: '100%',
  },
  label: {
    textAlign: 'left',
  },
})(Select);

// export const CustomSwitch = withStyles({
//   switchBase: {
//     "&$checked": {
//       color: "#E6C4A6",
//     },
//   },
// })(Switch);
