import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/storage';
import ThreeSevenTeenCoffee from '../Assets/Logos/317-Coffee.png';
import Authoritek from '../Assets/Logos/Authoritek.png';
import FlamingoConsulting from '../Assets/Logos/FlamingoConsulting.png';
import FrankenmuthSchools from '../Assets/Logos/FrankenmuthSchools.png';
import HGrille from '../Assets/Logos/HGrille.png';
import ManCave from '../Assets/Logos/ManCave.png';
import SaugatuckSchools from '../Assets/Logos/SaugatuckSchools.png';
import TownlineHatchery from '../Assets/Logos/TownlineHatchery.png';
import { getFirebaseUrl } from '../Config';
import {
  CoffeeRC,
  FrankenmuthRC,
  HGrilleRC,
  ManCaveRC,
  SaugatuckRC,
  TestRC,
  TownlineHatcheryRC,
} from './RemoteConfigInit';

let firebaseConfig = {};

let project = getFirebaseUrl();
let BUSINESS_NAME = 'Hudsonville-Grille';
let PARENT_SITE = 'https://hudsonvillegrille.com/';
let AppImage = HGrille;
let DisplayName = '';
let projectName = '';
let DEFAULT_CONFIG = {};
const remoteConfig = firebase.remoteConfig;
remoteConfig.settings = { minimumFetchIntervalMillis: 3600000 };
// https://firebase.google.com/docs/remote-config/use-config-web#throttling

switch (project) {
  case '?HGrille':
    firebaseConfig = {
      apiKey: process.env.REACT_APP_HGRILLE_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_HGRILLE_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_HGRILLE_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_HGRILLE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_HGRILLE_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_HGRILLE_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_HGRILLE_FIREBASE_MEASUREMENT_ID,
    };
    projectName = 'Hudsonville-Grille';
    DisplayName = 'Hudsonville Grille';
    AppImage = HGrille;
    DEFAULT_CONFIG = HGrilleRC;
    break;
  case '?Authoritek-Testing':
    firebaseConfig = {
      apiKey: process.env.REACT_APP_AUTHORITEK_TESTING_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_AUTHORITEK_TESTING_FIREBASE_AUTH_DOMAIN,
      databaseURL:
        process.env.REACT_APP_AUTHORITEK_TESTING_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_AUTHORITEK_TESTING_FIREBASE_PROJECT_ID,
      storageBucket:
        process.env.REACT_APP_AUTHORITEK_TESTING_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_AUTHORITEK_TESTING_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_AUTHORITEK_TESTING_FIREBASE_APP_ID,
      measurementId:
        process.env.REACT_APP_AUTHORITEK_TESTING_FIREBASE_MEASUREMENT_ID,
    };
    projectName = 'gr-template';
    BUSINESS_NAME = 'Authoritek';
    DisplayName = 'Authoritek';
    PARENT_SITE = 'https://www.authoritek.com/';
    AppImage = Authoritek;
    DEFAULT_CONFIG = TestRC;
    break;
  case '?317-Coffee':
    firebaseConfig = {
      apiKey: process.env.REACT_APP_317_COFFEE_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_317_COFFEE_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_317_COFFEE_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_317_COFFEE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_317_COFFEE_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_317_COFFEE_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_317_COFFEE_FIREBASE_MEASUREMENT_ID,
    };
    projectName = '317-Coffee';
    BUSINESS_NAME = '317-Coffee';
    DisplayName = '317 Coffee';
    PARENT_SITE = 'https://www.317coffee.com/';
    AppImage = ThreeSevenTeenCoffee;
    DEFAULT_CONFIG = CoffeeRC;
    break;
  case '?Saugatuck-Schools':
    firebaseConfig = {
      apiKey: process.env.REACT_APP_SAUGATUCK_SCHOOLS_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_SAUGATUCK_SCHOOLS_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_SAUGATUCK_SCHOOLS_FIREBASE_PROJECT_ID,
      storageBucket:
        process.env.REACT_APP_SAUGATUCK_SCHOOLS_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_SAUGATUCK_SCHOOLS_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_SAUGATUCK_SCHOOLS_FIREBASE_APP_ID,
      measurementId:
        process.env.REACT_APP_SAUGATUCK_SCHOOLS_FIREBASE_MEASUREMENT_ID,
    };
    projectName = 'Saugatuck-Schools';
    BUSINESS_NAME = 'Saugatuck-Schools';
    DisplayName = 'Saugatuck Schools';
    PARENT_SITE = 'https://saugatuckpublicschools.com/';
    AppImage = SaugatuckSchools;
    DEFAULT_CONFIG = SaugatuckRC;
    break;
  case '?Frankenmuth-Schools':
    firebaseConfig = {
      apiKey: process.env.REACT_APP_FRANKENMUTH_SCHOOLS_FIREBASE_API_KEY,
      authDomain:
        process.env.REACT_APP_FRANKENMUTH_SCHOOLS_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FRANKENMUTH_SCHOOLS_FIREBASE_PROJECT_ID,
      storageBucket:
        process.env.REACT_APP_FRANKENMUTH_SCHOOLS_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_FRANKENMUTH_SCHOOLS_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FRANKENMUTH_SCHOOLS_FIREBASE_APP_ID,
      measurementId:
        process.env.REACT_APP_FRANKENMUTH_SCHOOLS_FIREBASE_MEASUREMENT_ID,
    };
    projectName = 'Frankenmuth-Schools';
    BUSINESS_NAME = 'Frankenmuth-Schools';
    DisplayName = 'Frankenmuth Schools';
    PARENT_SITE = 'https://fmuthschools.com/';
    AppImage = FrankenmuthSchools;
    DEFAULT_CONFIG = FrankenmuthRC;
    break;
  case '?Townline-Hatchery':
    firebaseConfig = {
      apiKey: process.env.REACT_APP_TOWNLINE_HATCHERY_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_TOWNLINE_HATCHERY_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_TOWNLINE_HATCHERY_FIREBASE_PROJECT_ID,
      storageBucket:
        process.env.REACT_APP_TOWNLINE_HATCHERY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_TOWNLINE_HATCHERY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_TOWNLINE_HATCHERY_FIREBASE_APP_ID,
      measurementId:
        process.env.REACT_APP_TOWNLINE_HATCHERY_FIREBASE_MEASUREMENT_ID,
    };
    projectName = 'Townline-Hatchery';
    BUSINESS_NAME = 'Townline-Hatchery';
    DisplayName = 'Townline Hatchery';
    PARENT_SITE = 'https://townlinehatchery.com/';
    AppImage = TownlineHatchery;
    DEFAULT_CONFIG = TownlineHatcheryRC;
    break;
  case '?Man-Cave':
    firebaseConfig = {
      apiKey: process.env.REACT_APP_MAN_CAVE_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_MAN_CAVE_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_MAN_CAVE_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_MAN_CAVE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_MAN_CAVE_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_MAN_CAVE_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_MAN_CAVE_FIREBASE_MEASUREMENT_ID,
    };
    projectName = 'Man-Cave';
    BUSINESS_NAME = 'Man-Cave';
    DisplayName = 'The Man Cave UP';
    PARENT_SITE = 'https://www.themancaveup.com/';
    AppImage = ManCave;
    DEFAULT_CONFIG = ManCaveRC;
    break;
  case '?Flamingo-Consulting':
    firebaseConfig = {
      apiKey: process.env.REACT_APP_FLAMINGO_CONSULTING_FIREBASE_API_KEY,
      authDomain:
        process.env.REACT_APP_FLAMINGO_CONSULTING_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FLAMINGO_CONSULTING_FIREBASE_PROJECT_ID,
      storageBucket:
        process.env.REACT_APP_FLAMINGO_CONSULTING_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_FLAMINGO_CONSULTING_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FLAMINGO_CONSULTING_FIREBASE_APP_ID,
      measurementId:
        process.env.REACT_APP_FLAMINGO_CONSULTING_FIREBASE_MEASUREMENT_ID,
    };
    projectName = 'Flamingo-Consulting';
    BUSINESS_NAME = 'Flamingo-Consulting';
    DisplayName = 'Flamingo Consulting';
    PARENT_SITE = 'https://flamingoconsultingllc.com/';
    AppImage = FlamingoConsulting;
    DEFAULT_CONFIG = FlamingoConsulting;
    break;
  default:
    firebaseConfig = {
      apiKey: process.env.REACT_APP_HGRILLE_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_HGRILLE_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_HGRILLE_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_HGRILLE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_HGRILLE_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_HGRILLE_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_HGRILLE_FIREBASE_MEASUREMENT_ID,
    };
    projectName = 'Hudsonville-Grille';
    DisplayName = 'Hudsonville Grille';
    DEFAULT_CONFIG = HGrilleRC;
    AppImage = HGrille;
}

firebase.initializeApp(firebaseConfig);
remoteConfig.defaultConfig = { DEFAULT_CONFIG };

/* Export our firebase helper variables. */
export const auth = firebase.auth();

export const businessName = BUSINESS_NAME;

export const db = firebase.firestore();

export const birthdayRef = db.collection('Birthdays');

export const categoriesRef = db.collection('Categories');

export const couponsRef = db.collection('Coupons');

export const deleteField = firebase.firestore.FieldValue.delete();

export const displayName = DisplayName;

export const FV = firebase.firestore.FieldValue;

export const listingsRef = db.collection('Listings');

export const logo = AppImage;

export const notificationRef = db.collection('Notifications');

export const parentSite = PARENT_SITE;

export const postsRef = db.collection('CompanyPosts');

export const promosRef = db.collection('Promos');

export const punchRef = db.collection('Punchcards');

export const RC = remoteConfig;

export const rewardsRef = db.collection('Rewards');

export const staticUserRef = db.collection('Static-Users');

export const storageRef = firebase.storage().ref(projectName);

export const timestamp = firebase.firestore.FieldValue.serverTimestamp();

export const userRef = db.collection('Users');
