import { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";

export const PostsTable = ({ history, posts }) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const projectId = localStorage.getItem("appId");
  const headCells = [
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
    },
    {
      id: "body",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
  ];

  /* Sort the data by the given orderBy comparator. */
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;

    return 0;
  };

  /* Based on the order value, sort the data in descending or ascending order. */
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  /* Change the page number we're on. */
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  /* Change the amount of rows per page we're on. */
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  /* Navigate to the edit-post screen. */
  const handleNavigation = (post) => {
    history.push({ pathname: `/edit-post/${projectId}`, state: post });
  };

  /* Helper for sorting our posts. */
  const sortHandler = (sortId) => {
    handleRequestSort(sortId);
  };

  /* Sort handler checks if we are ordering by asc/desc and sets the state to the inputted id. */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /* Cut the description/body of the post short if it's too long. */
  const renderPostBody = (body) => {
    return body.length > 30 ? body.substr(0, 30) + "..." : body;
  };

  /* Sort a row based on the comparitor inputted. */
  const stableSort = (array, comparator) => {
    const formattedArray = array.map((el, index) => [el, index]);

    formattedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return formattedArray.map((el) => el[0]);
  };

  return (
    <div
      style={{
        marginTop: 30,
        maxWidth: 900,
        paddingBottom: 30,
        width: "100%",
      }}
    >
      <Paper elevation={3} style={{ padding: 30 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => sortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(posts, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((post, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    style={
                      index % 2
                        ? { backgroundColor: "#F7F7F7" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    <TableCell
                      align="left"
                      component="th"
                      id={post._id}
                      scope="row"
                      padding="none"
                    >
                      {post.title}
                    </TableCell>
                    <TableCell align="left">
                      {renderPostBody(post.body)}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleNavigation(post)}
                      >
                        Manage
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={posts.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
