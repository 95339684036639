import { useCallback, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Fade,
  LinearProgress,
  Modal,
  Paper,
} from "@material-ui/core";
import { CustomTextField } from "../Custom/CustomForm";
import { addCategory } from "../../Helpers/Data";

export const AddCategoryForm = ({ close, error, success }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  /* Add the category to firebase. */
  const callAddCategory = useCallback(async () => {
    const added = await addCategory(name);

    setLoading(false);
    return added === "SUCCESS"
      ? success("Your new category has been added!")
      : error(added);
  }, [error, name, success]);

  /* Handle the form submission. */
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (loading) callAddCategory();
  }, [callAddCategory, loading]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={styles.modal}
    >
      <Fade in={true}>
        <Paper elevation={3} style={styles.paper}>
          <form onSubmit={handleSubmit}>
            <h3 style={styles.title}>Add A New Category</h3>
            <CustomTextField
              variant="outlined"
              type="text"
              value={name}
              placeholder="Name of category"
              required
              onChange={(e) => setName(e.target.value)}
            />
            <hr style={styles.bar} />
            {loading && (
              <LinearProgress color="secondary" style={styles.fullwidth} />
            )}
            <div style={styles.buttoncontainer}>
              <Button
                color="primary"
                onClick={close}
                style={styles.buttonleft}
                variant="contained"
              >
                CLOSE
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={styles.buttonright}
              >
                SUBMIT
              </Button>
            </div>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

const styles = {
  bar: {
    marginBottom: 10,
    marginTop: 10,
    width: "100%",
  },
  buttoncontainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  buttonleft: {
    marginRight: 10,
    width: "100%",
  },
  buttonright: {
    marginLeft: 10,
    width: "100%",
  },
  fullwidth: { width: "100%" },
  modal: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  paper: { maxWidth: 600, padding: 30 },
  title: { textAlign: "center" },
};
