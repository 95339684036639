import { useState } from "react";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RC } from "./Api/FirebaseConfigHandler";
import "./App.css";
import { AuthProvider } from "./Context/auth";
import { AdminProfile, Dashboard } from "./Screens/Admin";
/* Import Screens */
import { Loading, Login } from "./Screens/Auth";
import { ManageBirthdays } from "./Screens/Birthdays";
import { ManageCategories } from "./Screens/Categories";
// Facebook stuff
import { FacebookMessaging, FacebookPosts } from "./Screens/Facebook";
import { AddListing, AllListings, EditListing } from "./Screens/Listings";
import { More } from "./Screens/Misc";
import { AddPost, AllPosts, EditPost } from "./Screens/Posts";
import { AddPromo, AllPromos, EditPromo } from "./Screens/Promos";
import { AddReward, AllRewards, EditReward } from "./Screens/Rewards";
import { AddTeamMember, AllTeamMembers, EditTeamMember } from "./Screens/Team";
import { AllUsers, UserProfile } from "./Screens/Users";
import { MobileWarning } from "./Screens/Util";

const App = () => {
  const [data, setData] = useState({});

  /* Fetch the newest remote-config key-value pairs and display content based on our remote flags. */
  const setRemoteFetch = async () => {
    await RC()
      .fetchAndActivate(300)
      .then(() => {
        const BIRTHDAYS = RC().getValue("birthdays").asBoolean();
        const FACEBOOK_MESSAGING = RC()
          .getValue("facebook_messaging")
          .asBoolean();
        const FACEBOOK_POSTS = RC().getValue("facebook_posts").asBoolean();
        const LISTINGS = RC().getValue("listings").asBoolean();
        let POSTS = RC().getValue("posts").asBoolean();
        const PROMOS = RC().getValue("promotions").asBoolean();
        const REWARDS = RC().getValue("rewards").asBoolean();
        const TEAM = RC().getValue("manual_teams").asBoolean();
        const USERS = RC().getValue("users").asBoolean();
        const ANDROID_LINK = RC().getValue("android_link").asString();
        const FACEBOOK_POSTS_IFRAME = RC()
          .getValue("facebook_posts_iframe")
          .asString();
        const IOS_LINK = RC().getValue("ios_link").asString();

        if (FACEBOOK_POSTS) POSTS = false;

        // TODO: Change this later.
        localStorage.setItem("BIRTHDAYS", BIRTHDAYS);
        localStorage.setItem("FACEBOOK_MESSAGING", FACEBOOK_MESSAGING);
        localStorage.setItem("FACEBOOK_POSTS", FACEBOOK_POSTS);
        localStorage.setItem("FACEBOOK_POSTS_IFRAME", FACEBOOK_POSTS_IFRAME);
        localStorage.setItem("LISTINGS", LISTINGS);
        localStorage.setItem("POSTS", POSTS);
        localStorage.setItem("PROMOS", PROMOS);
        localStorage.setItem("REWARDS", REWARDS);
        localStorage.setItem("TEAM", TEAM);
        localStorage.setItem("USERS", USERS);
        localStorage.setItem("ANDROID_LINK", ANDROID_LINK);
        localStorage.setItem("IOS_LINK", IOS_LINK);

        setData({ BIRTHDAYS, LISTINGS, POSTS, PROMOS, REWARDS, TEAM, USERS });

        return;
      })
      .catch((error) => {
        console.error("REMOTE FETCH ERROR:", error);
        return false;
      });
  };

  if (
    data.BIRTHDAYS === undefined ||
    data.LISTINGS === undefined ||
    data.POSTS === undefined ||
    data.PROMOS === undefined ||
    data.REWARDS === undefined ||
    data.TEAM === undefined ||
    data.USERS === undefined
  ) {
    setRemoteFetch();
  }

  /* Show/hide the facebook plugin based on the screen the user is on. */
  if (
    window.location.pathname.includes("facebook") &&
    window.FB &&
    window.FB.CustomerChat
  ) {
    window.FB.CustomerChat.show();
  } else if (window.FB && window.FB.CustomerChat) {
    window.FB.CustomerChat.hide();
  }

  // Check each remote config value to see if we need a given route.
  const renderRouteOptions = () => {
    let temp = [];

    if (data.BIRTHDAYS) {
      temp.push(<Route component={ManageBirthdays} path="/birthdays" />);
    }

    if (data.LISTINGS) {
      temp.push(<Route component={AddListing} path="/add-listing" />);
      temp.push(<Route component={AllListings} path="/listings" />);
      temp.push(<Route component={EditListing} path="/edit-listing" />);
      temp.push(
        <Route component={ManageCategories} path="/manage-categories" />
      );
    }

    if (data.POSTS) {
      temp.push(<Route component={AddPost} path="/add-post" />);
      temp.push(<Route component={AllPosts} path="/posts" />);
      temp.push(<Route component={EditPost} path="/edit-post" />);
    }

    if (data.PROMOS) {
      temp.push(<Route component={AddPromo} path="/add-promo" />);
      temp.push(<Route component={AllPromos} path="/promos" />);
      temp.push(<Route component={EditPromo} path="/edit-promo" />);
    }

    if (data.REWARDS) {
      temp.push(<Route component={AddReward} path="/add-reward" />);
      temp.push(<Route component={AllRewards} path="/rewards" />);
      temp.push(<Route component={EditReward} path="/edit-reward" />);
    }

    if (data.TEAM) {
      temp.push(<Route component={AllTeamMembers} path="/team" />);
      temp.push(<Route component={AddTeamMember} path="/add-teammember" />);
      temp.push(<Route component={EditTeamMember} path="/edit-teammember" />);
    }

    if (data.USERS) {
      temp.push(<Route component={AllUsers} path="/users" />);
      temp.push(<Route component={UserProfile} path="/user-profile" />);
    }

    return temp;
  };

  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route component={Dashboard} path="/dashboard" />
          <Route component={Login} path="/login" />
          <Route component={FacebookMessaging} path="/facebook" />
          <Route component={FacebookPosts} path="/facebook-posts" />

          {/* More screens here */}
          <Route component={AdminProfile} path="/my-profile" />
          <Route component={Dashboard} path="/dashboard" />
          <Route component={More} path="/more" />

          {renderRouteOptions()}

          {/* Loading and warning screens. */}
          <Route component={MobileWarning} path="/mobile-warning" />
          <Route component={Loading} path="/" />
        </Switch>
      </AuthProvider>
    </Router>
  );
};

const MobileView = () => {
  /* Show/hide the facebook plugin based on the screen the user is on. */
  if (
    window.location.pathname.includes("facebook") &&
    window.FB &&
    window.FB.CustomerChat
  ) {
    window.FB.CustomerChat.show();
  } else if (window.FB && window.FB.CustomerChat) {
    window.FB.CustomerChat.hide();
  }
  return (
    <Router>
      <Switch>
        <Route component={FacebookMessaging} path="/facebook" />
        <Route component={FacebookPosts} path="/facebook-posts" />
        <Route component={MobileWarning} path="/" />
      </Switch>
    </Router>
  );
};

export default isMobile ? MobileView : App;
