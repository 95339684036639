import { colors } from './Colors';

export const styles = {
  main: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
  },
  secondary: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  linkscontainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    marginTop: 100,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
  },
  linktitle: {
    textAlign: 'center',
    color: colors.black,
  },
  applinkscontainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  iosimage: {
    cursor: 'pointer',
    marginRight: 10,
    width: 200,
  },
  androidimage: {
    cursor: 'pointer',
    height: 100,
    marginLeft: 10,
  },
};
