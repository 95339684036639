import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { useState } from 'react';

export const RewardsTable = ({ history, rewards }) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('title');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRows] = useState(5);
  const projectId = localStorage.getItem('appId');
  const headCells = [
    {
      id: 'title',
      numeric: false,
      disablePadding: true,
      label: 'Title',
    },
    {
      id: 'body',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    { id: 'points', numeric: false, disablePadding: false, label: 'Points' },
  ];

  /* Sort the data by the given orderBy comparator. */
  const descendingComparator = (a, b) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  /* Based on the order value, sort the data in descending or ascending order. */
  const getComparator = () => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  /* Change the page number we're on. */
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  /* Change the amount of rows per page we're on. */
  const handleChangeRowsPerPage = (e) => {
    setRows(e.target.value);
  };

  /* Navigate to the edit-reward screen. */
  const handleNavigation = (reward) => {
    history.push({ pathname: `/edit-reward/${projectId}`, state: reward });
  };

  /* Helper for sorting our rewards. */
  const sortHandler = (sortId) => {
    handleRequestSort(sortId);
  };

  /* Sort handler checks if we are ordering by asc/desc and sets the state to the inputted id. */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /* Cut the description/body of the reward short if it's too long. */
  const renderRewardBody = (body) => {
    return body.length > 30 ? body.substr(0, 30) + '...' : body;
  };

  /* Sort a row based on the comparitor inputted. */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  return (
    <div style={{ marginTop: 30, width: '80%' }}>
      <Paper elevation={3} style={{ padding: 30 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => sortHandler(headCell.id)}
                      style={{ fontWeight: 'bold' }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="center" style={{ fontWeight: 'bold' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rewards, getComparator())
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((reward, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    style={
                      index % 2
                        ? { backgroundColor: '#F7F7F7' }
                        : { backgroundColor: '#FFF' }
                    }
                  >
                    <TableCell
                      align="left"
                      component="th"
                      id={reward._id}
                      scope="row"
                      padding="none"
                    >
                      {reward.title}
                    </TableCell>
                    <TableCell align="left">
                      {renderRewardBody(reward.body)}
                    </TableCell>
                    <TableCell align="left">{reward.points}</TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleNavigation(reward)}
                      >
                        Manage
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={rewards.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
