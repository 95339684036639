import { colors } from '../Colors';

export const styles = {
  screencontainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
  },
  headercontainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  metacontentcontainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  maincontainer: {
    alignItems: 'center',
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    marginTop: 50,
    width: '100%',
  },
  progresscontainer: {
    margin: 'auto',
    overflow: 'auto',
    width: '50%',
    height: '50%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  fullheight: {
    height: '100%',
    marginRight: 40,
  },
  fullwidth: {
    width: '100%',
  },
  divbuttoncontainer: {
    marginTop: 20,
    width: '100%',
  },
  buttoncontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 20,
  },
  backbuttoncontainer: {
    alignItems: 'flex-start',
    display: 'flex',
    marginLeft: 20,
    width: '100%',
  },
  backbutton: {
    marginBottom: 20,
  },
  button: {
    width: '100%',
    marginTop: 20,
  },
  accountcontentmetaheader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  metaheaderbuttoncontainer: {},
  formcontainer: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  formheader: {
    color: colors.darkgray,
  },
  formsubheader: {
    color: colors.darkgray,
  },
  formbreakline: {
    width: '100%',
    marginBottom: 30,
  },
  label: {
    color: colors.darkgray,
  },
  progressseccontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  contentcontainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: 700,
    width: '100%',
  },
  useravatar: { height: 150, marginTop: 30, width: 150 },
  usertextinput: { marginBottom: 20 },
  pininput: {
    marginBottom: 20,
    fontWeight: 'bold',
  },
  fillwidth: { width: '100%', marginBottom: 20 },
  userbuttoncontainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  userleftbutton: {
    width: '100%',
    marignRight: 5,
  },
  userrightbutton: { marginLeft: 10, width: '100%' },
  spaceleft: {
    fontWeight: 'bold',
    marginLeft: 10,
  },
  boldfont: {
    fontWeight: 'bold',
  },
};
