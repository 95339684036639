import {
  birthdayRef,
  categoriesRef,
  deleteField,
  FV,
  listingsRef,
  postsRef,
  promosRef,
  rewardsRef,
  staticUserRef,
  timestamp,
  userRef,
} from "../Api/FirebaseConfigHandler";
import { generateId } from "./Generators";

/* Get a single user from the system. */
export async function getUser(userId) {
  const user = await userRef.doc(userId).get();
  return user?.data();
}

/* Update the user's phonenumber, PIN, and role from the user-profile screen. */
export async function updateUser(phonenumber, PIN, role, userId) {
  if (role === "User") {
    return await userRef
      .doc(userId)
      .update({
        phonenumber: FV.delete(),
        PIN: FV.delete(),
        role,
      })
      .then(() => {
        return "SUCCESS";
      })
      .catch((error) => {
        return error;
      });
  } else {
    return await userRef
      .doc(userId)
      .update({ phonenumber, PIN, role })
      .then(() => {
        return "SUCCESS";
      })
      .catch((error) => {
        return error;
      });
  }
}

/* Get all categories from our firestore collection. */
export async function getAllCategories() {
  let temp = [];
  let categories = await categoriesRef.orderBy("name", "asc").get();
  for (let i = 0; i < categories.docs.length; i++) {
    let Obj = categories.docs[i].data();
    if (!Obj.hidden) {
      temp.push(Obj.name);
    }
  }
  return temp;
}

/* Get all of the data from the categories. */
export async function getAllCategoriesData() {
  let temp = [];
  let categories = await categoriesRef.orderBy("name", "asc").get();
  for (let i = 0; i < categories.docs.length; i++) {
    let Obj = categories.docs[i].data();
    // if (!Obj.hidden) {
    temp.push(Obj);
    // }
  }
  return temp;
}

/* Add a new category to Firestore. */
export async function addCategory(name) {
  const uniqueId = generateId();
  return await categoriesRef
    .doc(uniqueId)
    .set({
      _id: uniqueId,
      createdAt: timestamp,
      hidden: false,
      name,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Edit a given category. */
export async function editCategory(category) {
  return await categoriesRef
    .doc(category._id)
    .update({
      name: category.name,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Remove a given category. */
export async function removeCategory({ _id }) {
  return await categoriesRef
    .doc(_id)
    .update({ hidden: true })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Toggle the hidden flag for a given category in our database. */
export async function toggleCategory(_id, hidden) {
  return await categoriesRef
    .doc(_id)
    .update({ hidden })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Get all listings from our firestore collection. */
export async function getAllListings() {
  // Not completely sold on if we should show even hidden listings.
  let temp = [];
  let listings = await listingsRef.orderBy("order", "asc").get();
  for (let i = 0; i < listings.docs.length; i++) {
    let Obj = listings.docs[i].data();
    if (!Obj.hidden) {
      temp.push(Obj);
    }
  }
  return temp;
}

/* Add a new listing */
export async function addListing(
  category,
  { description, featured, imageName, name, price, url }
) {
  const uniqueId = generateId();

  // Get the last order number from firestore
  let lastOrder = 0;
  let query = await listingsRef.orderBy("order", "desc").limit(1).get();

  // If query is not empty.
  if (!query.empty) {
    lastOrder = query.docs[0].data();
    lastOrder = lastOrder.order + 1;
  }

  let order = lastOrder;

  const added = await listingsRef
    .doc(uniqueId)
    .set({
      _id: uniqueId,
      category,
      createdAt: timestamp,
      description,
      featured,
      hidden: false,
      imageName,
      name,
      order,
      price,
      url,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });

  return added;
}

/* Edit an existing listing. */
export async function editListing({
  _id,
  category,
  description,
  featured,
  hidden,
  imageName,
  name,
  price,
  url,
}) {
  return await listingsRef
    .doc(_id)
    .update({
      category,
      description,
      featured,
      hidden,
      imageName,
      name,
      price,
      url,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Toggle the featured flag on a given listing. */
export async function toggleListingFeatured(listing) {
  return await listingsRef
    .doc(listing._id)
    .update({ featured: listing.featured })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Save all listings in this list. */
export async function updateAllListings(listings) {
  try {
    // Basically, if a listing is not equal to the one in Firestore, overwrite it with the one in this list.
    for (let i = 0; i < listings.length; i++) {
      // Get data of listing in Firestore
      let fireListing = listingsRef.doc(listings[i]._id).get();
      fireListing = (await fireListing).data();

      // If this listing was updated.
      if (listings[i].order !== fireListing.order) {
        listingsRef.doc(listings[i]._id).update({ order: listings[i].order });
      }
    }

    return "SUCCESS";
  } catch (error) {
    return error;
  }
}

/* Remove a selected listing in Firestore. */
export async function removeListing(listingId) {
  return await listingsRef
    .doc(listingId)
    .update({ hidden: true })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Get all users from our Firestore collection. */
export async function getAllUsers() {
  let temp = [];
  const docdata = await userRef.orderBy("name").get();
  for (let i = 0; i < docdata.docs.length; i++) {
    temp.push(docdata.docs[i].data());
  }
  return temp;
}

/* Up/downgrade a user's role in Firestore. */
export async function changeUserRole(role, userId) {
  if (role === "User") {
    return await userRef
      .doc(userId)
      .update({ PIN: deleteField, role })
      .then(() => {
        return "SUCCESS";
      })
      .catch((error) => {
        return error;
      });
  } else {
    return await userRef
      .doc(userId)
      .update({ role })
      .then(() => {
        return "SUCCESS";
      })
      .catch((error) => {
        return error;
      });
  }
}

/* Change the PIN of a given user. */
export async function updateUserPIN(userId, PIN) {
  return await userRef
    .doc(userId)
    .update({ PIN })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Check and see if the PIN inputted belongs to the user. */
export async function checkPIN(authId, PIN) {
  let userPIN = await userRef.doc(authId).get();
  userPIN = userPIN.data().PIN;
  return PIN === userPIN ? true : false;
}

/* Add a new post to our firestore instance.
   Posts will act as "Notifications" from Admin's of the site. */
export async function addPost(body, title) {
  const uniqueId = generateId();

  return await postsRef
    .doc(uniqueId)
    .set({
      _id: uniqueId,
      body: body,
      createdAt: timestamp,
      hidden: false,
      title: title,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Edit an existing company post. */
export async function editPost({ _id, body, title }) {
  return await postsRef
    .doc(_id)
    .update({ body, title })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Remove an existing company post. */
export async function removePost(_id) {
  return await postsRef
    .doc(_id)
    .update({ hidden: true })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Get the last company post in firestore and return it. */
export async function getLastPost() {
  const posts = await postsRef.orderBy("createdAt", "desc").limit(1).get();

  if (!posts._docs[0]) {
    return [];
  }

  return posts._docs[0]._data;
}

/* Add a new promo to our firestore instance. */
export async function addPromo(body, title) {
  const uniqueId = generateId();

  return await promosRef
    .doc(uniqueId)
    .set({
      _id: uniqueId,
      body: body,
      createdAt: timestamp,
      hidden: false,
      title: title,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Edit an existing company promo. */
export async function editPromo({ _id, body, title }) {
  return await promosRef
    .doc(_id)
    .update({ body, title })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Remove an existing company promo. */
export async function removePromo(_id) {
  return await promosRef
    .doc(_id)
    .update({ hidden: true })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Get the templated birthday message. */
export async function getBirthdayMessage() {
  const birthdayObj = await birthdayRef
    .where("hidden", "==", false)
    .limit(1)
    .get();

  if (!birthdayObj.empty) {
    return birthdayObj.docs[0].data();
  }

  const uniqueId = generateId();

  return {
    _id: uniqueId,
    body: "",
    createdAt: timestamp,
    hidden: false,
    title: "",
  };
}

/* Update the templated birthday message. */
export async function updateBirthdayMessage({ _id, body, hidden, title }) {
  const updated = await birthdayRef
    .doc(_id)
    .update({
      body,
      hidden,
      title,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });

  if (updated !== "SUCCESS") {
    return await birthdayRef
      .doc(_id)
      .set({
        _id,
        body,
        createdAt: timestamp,
        hidden,
        title,
      })
      .then(() => {
        return "SUCCESS";
      })
      .catch((error) => {
        return error;
      });
  }

  return updated;
}

/* Get all teammates from our Firestore collection. */
export async function getAllTeammembers() {
  try {
    let temp = [];
    const docdata = await staticUserRef.where("hidden", "==", false).get();

    for (let i = 0; i < docdata.docs.length; i++) {
      temp.push(docdata.docs[i].data());
    }
    return temp;
  } catch (error) {
    console.error("ERROR:", error);
    return [];
  }
}

/* Add a new teammate */
export async function addTeammember({ bio, imageName, name, url }) {
  const uniqueId = generateId();

  const added = await staticUserRef
    .doc(uniqueId)
    .set({
      _id: uniqueId,
      avatar: url,
      bio,
      createdAt: timestamp,
      hidden: false,
      imageName,
      name,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });

  return added;
}

/* Edit a given teammate. */
export async function editTeammember({ _id, bio, imageName, name, url }) {
  return await staticUserRef
    .doc(_id)
    .update({
      avatar: url,
      bio,
      imageName,
      name,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Delete a given teammate. */
export async function removeTeammember(_id) {
  return await staticUserRef
    .doc(_id)
    .update({
      hidden: true,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Add a new reward to our firestore instance. */
export async function addReward(body, points, title) {
  const uniqueId = generateId();

  return await rewardsRef
    .doc(uniqueId)
    .set({
      _id: uniqueId,
      body,
      createdAt: timestamp,
      hidden: false,
      points,
      title,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Edit an existing reward. */
export async function editReward({ _id, body, points, title }) {
  return await rewardsRef
    .doc(_id)
    .update({ body, points, title })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Remove an existing reward. */
export async function removeReward(_id) {
  return await rewardsRef
    .doc(_id)
    .update({ hidden: true })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}
