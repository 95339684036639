import { Box, Button, LinearProgress, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// import { storageRef } from "../../Api/FirebaseConfigHandler";
// Import components
import {
  CustomButton,
  CustomTextField,
} from '../../Components/Custom/CustomForm';
import useAuth from '../../Hooks/useAuth';

export const EditRewardForm = ({
  confirmDelete,
  error,
  reward,
  submit,
  submitting,
}) => {
  const { user } = useAuth();

  /* Check to see if the authenticated user is allowed to change a reward or delete it. */
  const isDisabled = (action, submitting) => {
    if (submitting) {
      return true;
    } else if (
      (user.role === 'Manager' && action !== 'DELETE') ||
      user.role === 'Admin'
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        padding: 40,
      }}
    >
      <h3 style={{ textAlign: 'center' }}>Edit This Reward</h3>
      <Formik
        initialValues={{
          body: reward.body,
          hidden: reward.hidden,
          points: reward.points,
          title: reward.title,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          body: Yup.string()
            .min(4)
            .max(255)
            .required('Please enter a description for this reward.'),
          hidden: Yup.boolean(),
          points: Yup.number()
            .min(1)
            .required('Please enter some points for this reward.'),
          title: Yup.string()
            .min(4)
            .max(255)
            .required('Please enter a title for this reward.'),
        })}
        onSubmit={async (values, { setErrors, setStatus }) => {
          try {
            // Do some last minute validation here.

            // Send perfect request. Uncomment the imagename and url assignments if we change to image support for posts.
            let st = values;
            // st.imageName = imageName;
            // st.url = url;

            submit(st);

            setStatus({ success: true });
          } catch (err) {
            console.error('CAUGHT ERROR:', err);
            error(err);

            setStatus({ success: false });
            setErrors({ submit: err });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label>Title</label>
            <CustomTextField
              error={Boolean(touched.title && errors.title)}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              name="title"
              style={{ marginBottom: 20 }}
              value={values.title}
              variant="outlined"
            />
            <label>Description</label>
            <CustomTextField
              error={Boolean(touched.body && errors.body)}
              fullWidth
              multiline
              name="body"
              onBlur={handleBlur}
              onChange={handleChange}
              rows={8}
              style={{ marginBottom: 20 }}
              value={values.body}
              variant="outlined"
            />
            <label>Points Needed</label>
            <CustomTextField
              error={Boolean(touched.points && errors.points)}
              fullWidth
              name="points"
              onBlur={handleBlur}
              onChange={handleChange}
              style={{ marginBottom: 20, width: '100%' }}
              type="tel"
              value={values.points}
              variant="outlined"
            />
            <hr style={{ width: '100%' }} />
            {submitting && (
              <LinearProgress
                color="primary"
                style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
              />
            )}
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <Button
                color="secondary"
                disabled={isDisabled('DELETE', submitting)}
                fullWidth
                onClick={confirmDelete}
                style={{ marginRight: 10 }}
                variant="contained"
              >
                DELETE
              </Button>
              <CustomButton
                color="primary"
                disabled={isDisabled('SUBMIT', submitting)}
                fullWidth
                style={{ marginLeft: 10 }}
                type="submit"
                variant="contained"
              >
                SUBMIT
              </CustomButton>
            </Box>
            <Box style={{ marginTop: 10 }}>
              {errors.submit && (
                <Alert severity="error">
                  <div>{errors.submit}</div>
                </Alert>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

EditRewardForm.propTypes = {
  confirmDelete: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  reward: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};
