import { useState } from "react";
import {
  Backdrop,
  Button,
  Fade,
  LinearProgress,
  Modal,
  Paper,
} from "@material-ui/core";
import { CustomTextField } from "..//Custom/CustomForm";

export const ManageModal = ({ category, close, loading, open, submit }) => {
  const [name, setName] = useState(category.name);

  const handleSubmit = () => {
    const cat = category;
    cat.name = name;
    submit("", cat);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Fade in={true}>
        <Paper elevation={3} style={{ maxWidth: 600, padding: 30 }}>
          <h3 style={{ marginBottom: 20, textAlign: "center" }}>
            Edit Category
          </h3>
          <CustomTextField
            id="outlined-basic"
            variant="outlined"
            style={{ width: "100%" }}
            required
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <hr style={{ marginBottom: 10, width: "100%" }} />
          {loading && (
            <LinearProgress
              color="secondary"
              style={{ marginBottom: 10, width: "100%" }}
            />
          )}
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={close}
              style={{ marginRight: 10, width: "100%" }}
            >
              CLOSE
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ width: "100%" }}
            >
              SUBMIT
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};
