import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { storageRef } from '../../Api/FirebaseConfigHandler';
// Import components
import {
  CustomButton,
  CustomTextField,
} from '../../Components/Custom/CustomForm';
import { ImageModal } from '../Misc';

export const AddTeammateForm = ({ error, submit, submitting }) => {
  const [imageName, setImageName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');

  /* Show some loading animation while the image is processing. */
  const handlePictureUpload = (files) => {
    setLoading(true);
    uploadPicture(files);
  };

  /* Upload the image blob to firebase storage then save the download url to our teammate object. */
  const uploadPicture = async (files) => {
    try {
      // We only want 1 image to be uploaded
      let image = files[0];

      const ref = storageRef.child(`/Team/${image.name}`);

      const downloadUrl = await ref.put(image).then(async () => {
        return await ref.getDownloadURL();
      });

      setImageName(image.name);
      setUrl(downloadUrl);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      error(err);
    }
  };

  /* Show modal to blow up image with onclick. */
  const enhanceImage = () => {
    setShowModal(true);
  };

  /* Close all modals */
  const closeModals = () => {
    setShowModal(false);
  };

  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        padding: 40,
      }}
    >
      <h3 style={{ textAlign: 'center' }}>Create A New Teammate</h3>
      <Formik
        initialValues={{
          bio: '',
          name: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          bio: Yup.string()
            .min(10)
            .required('Please enter a bio for this teammate.'),
          name: Yup.string().required('Please enter a name for this teammate.'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // Do some last minute validation here.
            if (!imageName || !url || imageName.length < 1 || url.length < 1) {
              // eslint-disable-next-line no-new-wrappers
              throw new String('Please upload an image for this listing.');
            }

            setSubmitting(true);

            let st = values;
            st.imageName = imageName;
            st.url = url;
            submit(st);

            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error('CAUGHT ERROR:', err);

            setStatus({ success: false });
            setErrors({ submit: err });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <CustomTextField
              error={Boolean(touched.name && errors.name)}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Name"
              style={{ marginBottom: 20, width: '100%' }}
              value={values.name}
              variant="outlined"
            />
            <CustomTextField
              error={Boolean(touched.bio && errors.bio)}
              multiline
              name="bio"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Biography"
              rows={8}
              style={{ marginBottom: 20, width: '100%' }}
              value={values.bio}
              variant="outlined"
            />
            {url && url.length > 0 && (
              <div style={{ marginBottom: 10 }}>
                <img
                  alt="listing-pic"
                  src={url}
                  onClick={enhanceImage}
                  style={{
                    borderRadius: 10,
                    cursor: 'pointer',
                    height: '100%',
                    width: '100%',
                  }}
                />
              </div>
            )}

            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 20,
                marginTop: 20,
                width: '100%',
              }}
            >
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{
                  backgroundColor: '#424242',
                  display: 'none',
                  width: '100%',
                }}
                onChange={(e) => handlePictureUpload(e.target.files)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  color="primary"
                  component="span"
                  variant="contained"
                  style={{ fontWeight: 'bold', width: '100%' }}
                >
                  Upload Image
                </Button>
              </label>
              {loading && (
                <CircularProgress color="primary" style={{ marginLeft: 20 }} />
              )}
            </div>

            <hr style={{ width: '100%' }} />
            {submitting && (
              <LinearProgress
                color="primary"
                style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
              />
            )}
            <CustomButton
              color="primary"
              disabled={submitting || isSubmitting}
              type="submit"
              variant="contained"
            >
              SUBMIT
            </CustomButton>
            <Box style={{ marginTop: 10 }}>
              {errors.submit && (
                <Alert severity="error">
                  <div>{errors.submit}</div>
                </Alert>
              )}
            </Box>
          </form>
        )}
      </Formik>
      {showModal && (
        <ImageModal close={closeModals} imgUrl={url} open={showModal} />
      )}
    </Paper>
  );
};

AddTeammateForm.propTypes = {
  error: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};
