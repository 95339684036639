import React from 'react';
import example from '../../Assets/Examples/Mobile_posts.png';

/* This component aims to hold the "how to" text above the posts management. */
export const HowToPosts = () => (
  <div
    style={{
      backgroundColor: '#e8e8e8',
      width: '100%',
    }}
  >
    <div
      style={{
        paddingBottom: 20,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 10,
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        {/* Container for image */}
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '50%',
            justifyContent: 'center',
            maxWidth: '20%',
            minWidth: '15%',
          }}
        >
          <img
            alt="example.png"
            src={example}
            style={{
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              borderRadius: 4,
              height: '50%',
              width: '90%',
            }}
          />
        </div>
        {/* Container for the how to text. */}
        <div style={{ padding: 20, width: '50%' }}>
          <h1>Manage your Posts</h1>
          <p>
            These are your system-wide posts. Any user can view these from the
            app! Keep them informed on what's happening with your company or
            send a notification to run a new promotion.
          </p>
          <p>
            Each feature of this screen is available based on your role. For
            example, Admin's can do anything and Manager's and Employee's
            Employee's can only view the different posts.
          </p>
          <p>
            To add a new post, please click the button next to the search bar
            that says "Add New Post".
          </p>
        </div>
      </div>
      <hr
        style={{
          backgroundColor: '#424242',
          borderColor: '#000',
          height: 5,
          borderRadius: 30,
          marginTop: 40,
          width: '95%',
        }}
      />
    </div>
  </div>
);
