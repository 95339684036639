import { Button, Paper } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { CustomTextField } from '../../Components/Custom/CustomForm';
import { GenericSnackbar, Header, SideBar } from '../../Components/Misc';
import { getBirthdayMessage, updateBirthdayMessage } from '../../Helpers/Data';
import { getFormattedProjectName } from '../../Helpers/Utils';

/* This component aims to get the templated birthday message and allows admins to edit it.
   The message will be sent out on someone's birthday and probably will offer some discount if the 
   user shows the message in store. */
export const ManageBirthdays = ({ history }) => {
  const [_id, setId] = useState(null);
  const [body, setBody] = useState('');
  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [snackbar, setSnackbar] = useState(false);
  const [title, setTitle] = useState('');
  const projectId = localStorage.getItem('appId');
  const helmetTitle = getFormattedProjectName();

  /* Get our data when the component mounts. */
  useEffect(() => {
    /* Call our helper function to get this data. */
    const callGetBirthdayMessage = async () => {
      const birthdayObj = await getBirthdayMessage();
      setId(birthdayObj._id);
      setBody(birthdayObj.body);
      setHidden(birthdayObj.hidden);
      setTitle(birthdayObj.title);
    };

    callGetBirthdayMessage();
  }, []);

  /* Close the snackbar */
  const closeModals = () => {
    setMessage('');
    setSnackbar(false);
  };

  /* Show some updating UI here then call our update function. */
  const showLoading = () => {
    if (body.length > 0 && title.length > 0) {
      setLoading(true);
    }
  };

  /* Update the birthday message in firestore. */
  const callUpdateBirthdayMessage = useCallback(async () => {
    const updated = await updateBirthdayMessage({ _id, body, hidden, title });

    const msg =
      updated === 'SUCCESS'
        ? 'Success! Your message birthday message has been updated. Users from now on will receive the updated message.'
        : updated.message;

    setLoading(false);
    setMessage(msg);
    setSnackbar(true);
  }, [_id, body, hidden, title]);

  useEffect(() => {
    if (loading) callUpdateBirthdayMessage();
  }, [callUpdateBirthdayMessage, loading]);

  return (
    <>
      <Helmet title={`${helmetTitle} | Birthdays`} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Birthday" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Header
            history={history}
            projectId={projectId}
            screen="Manage My Users"
          />

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
              marginTop: 100,
            }}
          >
            {/* Show the birthday form here. */}
            <Paper
              elevation={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 400,
                padding: 40,
              }}
            >
              <h3 style={{ textAlign: 'center' }}>
                Change these fields to update your birthday message.
              </h3>
              <h6 style={{ textAlign: 'center' }}>
                After you press submit, anyone who has an upcoming birthday will
                receive this message on their birthday.
              </h6>
              <form>
                <CustomTextField
                  variant="outlined"
                  required
                  style={{ marginBottom: 20, width: '100%' }}
                  type="text"
                  value={title}
                  placeholder="Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <CustomTextField
                  variant="outlined"
                  required
                  style={{ marginBottom: 20, width: '100%' }}
                  type="text"
                  multiline
                  rowsMax={3}
                  value={body}
                  placeholder="Description"
                  onChange={(e) => setBody(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <Button
                    color="primary"
                    disabled={loading}
                    onClick={showLoading}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </Paper>

            {/* Show conditionally rendered comps heree */}
            {snackbar && (
              <GenericSnackbar
                close={closeModals}
                message={message}
                open={snackbar}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
