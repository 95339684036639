import { CircularProgress } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import { auth } from '../../Api/FirebaseConfigHandler';
import { signOutRedirect } from '../../Helpers/Auth';
import { getUser } from '../../Helpers/Data';
import { styles } from '../../Styles/Loading';

export const Loading = ({ history }) => {
  const navigateUser = useCallback(
    (slug) => {
      const projectId = localStorage.getItem('appId');
      history.push(`/${slug}/${projectId}`);
    },
    [history]
  );

  const checkPermissions = useCallback(
    async (user) => {
      const data = await getUser(user.uid);

      if (
        data.role === 'Admin' ||
        data.role === 'Manager' ||
        data.role === 'Employee'
      ) {
        navigateUser('dashboard');
      } else {
        signOutRedirect();
      }
    },
    [navigateUser]
  );

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      !!user ? checkPermissions(user) : navigateUser('login');
    });

    // Wait 2 seconds to unsubscribe to authentication listener.
    setTimeout(() => {
      // authCheck();
    }, 2000);
  }, [checkPermissions, navigateUser]);

  return (
    <div style={styles.main}>
      <div style={styles.secondary}>
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};
