import { Button } from '@material-ui/core';
import React, { useState } from 'react';
// Import Components
import {
  GenericAddButton,
  GenericSnackbar,
  Header,
  SideBar,
} from '../../Components/Misc';
import { ConfirmDeleteModal } from '../../Components/Modals';
import { EditRewardForm } from '../../Components/Rewards';
import { editReward, removeReward } from '../../Helpers/Data';

export const EditReward = ({ history, location }) => {
  const [message, setMessage] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const projectId = localStorage.getItem('appId');

  /* Show some loading UI while we edit the document in firebase storage. */
  const showLoadingProcess = (state) => {
    setSubmitting(true);
    callEditReward(state);
  };

  /* Find the document in Firestore and edit the appropriate fields. */
  const callEditReward = async (state) => {
    // Parse through what's changed and what hasn't changed and save to the reward object.
    const reward = location.state;
    const stateReward = state;

    reward.body = stateReward.body ? stateReward.body : reward.body;
    reward.points = stateReward.points
      ? parseFloat(stateReward.points)
      : parseFloat(reward.points);
    reward.title = stateReward.title ? stateReward.title : reward.title;

    const edited = await editReward(reward);
    const shouldShow = edited === 'SUCCESS';
    const msg = shouldShow ? 'Your reward has been updated!' : edited;
    setSubmitting(false);
    showSnackbar(msg, shouldShow);
  };

  /* Close all modals. */
  const closeModals = () => {
    setShowDeleteModal(false);
    setSnackbar(false);
  };

  /* Initiate the redirect timer, used for when operation is successful and user needs to go back. */
  const initRedirectTimer = () => {
    setTimeout(() => {
      navigateBack();
    }, 3000);
  };

  /* Send the user to the previous screen. */
  const navigateBack = () => {
    history.push({ pathname: `/rewards/${projectId}` });
  };

  /* Call our removeReward function in Data.js */
  const callRemoveReward = async () => {
    const rewardId = location.state._id;
    const removed = await removeReward(rewardId);
    const shouldShow = removed === 'SUCCESS';
    const msg = shouldShow
      ? 'Your reward has been successfully deleted. Customers cannot use it anymore.'
      : removed;
    setShowDeleteModal(false);
    showSnackbar(msg, shouldShow);
  };

  /* Show modal that asks the user to confirm deleting a reward. */
  const showConfirmDeleteModal = () => {
    setShowDeleteModal(true);
  };

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = (msg, redirect) => {
    setMessage(msg);
    setSnackbar(true);
    if (redirect) {
      initRedirectTimer();
    }
  };

  const reward = location.state;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <SideBar history={history} projectId={projectId} screen="Rewards" />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Header
          history={history}
          projectId={projectId}
          screen="Manage My Rewards"
        />

        <div
          style={{
            alignItems: 'center',
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            maxWidth: 600,
            width: '100%',
            marginTop: 100,
          }}
        >
          <div style={{ marginBottom: 30, paddingLeft: 120, width: '100%' }}>
            <Button
              color="primary"
              onClick={history.goBack}
              variant="contained"
            >
              Back
            </Button>
          </div>
          <EditRewardForm
            confirmDelete={showConfirmDeleteModal}
            error={showSnackbar}
            reward={reward}
            submit={showLoadingProcess}
            submitting={submitting}
          />

          {/* Show a back button in the top left corner of the page. */}
          <div style={{ position: 'fixed', left: 100, top: 80 }}>
            <GenericAddButton
              action={navigateBack}
              screen="rewards"
              text="BACK"
            />
          </div>
          {/* Show modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
          {showDeleteModal && (
            <ConfirmDeleteModal
              category="reward"
              close={closeModals}
              submit={callRemoveReward}
            />
          )}
        </div>
      </div>
    </div>
  );
};
