import { auth } from "../Api/FirebaseConfigHandler";

/* Used to sign out AT the auth wall. */
export async function signOut() {
  try {
    return await auth.signOut().then(() => {
      return "SUCCESS";
    });
  } catch (error) {
    return null;
  }
}

/* Used if signing out from a screen besides the auth wall. */
export async function signOutRedirect(props) {
  const projectId = localStorage.getItem("appId");
  try {
    await auth.signOut();
    props.history.push(`/login/${projectId}`);
  } catch (error) {
    return error;
  }
}
