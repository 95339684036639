import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { storageRef } from '../../Api/FirebaseConfigHandler';
// Import components
import {
  CustomButton,
  CustomTextField,
} from '../../Components/Custom/CustomForm';
import useAuth from '../../Hooks/useAuth';
import { ImageModal } from '../Misc';

export const EditTeammateForm = ({
  confirmDelete,
  error,
  submit,
  submitting,
  teammate,
}) => {
  const { user } = useAuth();
  const [imageName, setImageName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState(null);

  /* Close the image modal. */
  const closeModals = () => {
    setShowModal(false);
  };

  /* Show some loading animation while the image is processing. */
  const handlePictureUpload = (files) => {
    setLoading(true);
    uploadPicture(files);
  };

  /* Check to see if the authenticated user is allowed to change a teammate or delete it. */
  const isDisabled = (action, submitting) => {
    if (submitting) {
      return true;
    } else if (
      (user.role === 'Manager' && action !== 'DELETE') ||
      user.role === 'Admin'
    ) {
      return false;
    } else {
      return true;
    }
  };

  /* Upload the image blob to firebase storage then save the download url to our teammate object. */
  const uploadPicture = async (files) => {
    try {
      // We only want 1 image to be uploaded
      const image = files[0];

      const ref = storageRef.child(`/Team/${image.name}`);

      const downloadUrl = await ref.put(image).then(async () => {
        return await ref.getDownloadURL();
      });

      setImageName(image.name);
      setLoading(false);
      setUrl(downloadUrl);
    } catch (err) {
      console.error(err);
      setLoading(false);
      error(err);
    }
  };

  /* Show modal to blow up image with onclick. */
  const enhanceImage = () => {
    setShowModal(true);
  };

  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        padding: 40,
      }}
    >
      <h3 style={{ textAlign: 'center' }}>Edit {teammate.name}'s info</h3>
      <Formik
        initialValues={{
          bio: teammate.bio,
          name: teammate.name,
          url: teammate.url,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          bio: Yup.string()
            .min(4)
            .max(255)
            .required('Please enter a biography for this teammate.'),
          name: Yup.string()
            .min(4)
            .max(255)
            .required('Please enter a name for this teammate.'),
          url: Yup.string().required(
            'Please upload a profile picture for this teammate.'
          ),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // Do some last minute validation here.

            // Send perfect request. Uncomment the imagename and url assignments if we change to image support for posts.
            let st = values;
            st.imageName = imageName;
            st.url = url;

            submit(st);

            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error('CAUGHT ERROR:', err);
            error(err);
            setStatus({ success: false });
            setErrors({ submit: err });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label>Name</label>
            <CustomTextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              value={values.name}
              variant="outlined"
            />
            <label>Description</label>
            <CustomTextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              multiline
              name="bio"
              onBlur={handleBlur}
              onChange={handleChange}
              rows={8}
              style={{ marginBottom: 20 }}
              value={values.bio}
              variant="outlined"
            />
            <div style={{ marginBottom: 10 }}>
              <img
                alt="member-pic"
                src={url}
                onClick={enhanceImage}
                style={{
                  borderRadius: 10,
                  cursor: 'pointer',
                  height: '100%',
                  width: '100%',
                }}
              />
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 20,
              }}
            >
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{
                  backgroundColor: '#424242',
                  display: 'none',
                  width: '100%',
                }}
                onChange={(e) => handlePictureUpload(e.target.files)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  color="primary"
                  component="span"
                  disabled={isDisabled('UPLOAD', submitting)}
                  variant="contained"
                  style={{ fontWeight: 'bold' }}
                >
                  Replace Image
                </Button>
              </label>
              {loading && (
                <CircularProgress color="primary" style={{ marginLeft: 20 }} />
              )}
            </div>

            {/* <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <Checkbox
            checked={hidden !== undefined ? hidden : false}
            onChange={(e) => this.setState({ hidden: e.target.checked })}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <p>Is this user hidden?</p>
        </div> */}

            <hr style={{ width: '100%' }} />
            {submitting && (
              <LinearProgress
                color="primary"
                style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
              />
            )}
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <Button
                color="secondary"
                disabled={isDisabled('DELETE', submitting)}
                fullWidth
                style={{ marginRight: 10 }}
                onClick={confirmDelete}
                variant="contained"
              >
                DELETE
              </Button>
              <CustomButton
                color="primary"
                disabled={isDisabled('SUBMIT', submitting)}
                fullWidth
                style={{ marginLeft: 10 }}
                type="submit"
                variant="contained"
              >
                SUBMIT
              </CustomButton>
              {showModal && (
                <ImageModal close={closeModals} imgUrl={url} open={showModal} />
              )}
            </Box>
            <Box style={{ marginTop: 10 }}>
              {errors.submit && (
                <Alert severity="error">
                  <div>{errors.submit}</div>
                </Alert>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

EditTeammateForm.propTypes = {
  confirmDelete: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  teammate: PropTypes.object.isRequired,
};
