import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { addListing, getAllCategories } from "../../Helpers/Data";

// Import components
import {
  Header,
  GenericAddButton,
  GenericSnackbar,
  SideBar,
} from "../../Components/Misc";
import { AddListingForm } from "../../Components/Listings";

/* Add a new listing item. */
export const AddListing = ({ history, location }) => {
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const projectId = localStorage.getItem("appId");

  /* Get all categories from our database. */
  const getCategories = useCallback(async () => {
    const data = await getAllCategories();
    setCategories(data);
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  /* Save the listing in firebase storage. */
  const showLoadingProcess = (state) => {
    setSubmitting(true);
    callAddListing(state);
  };

  /* Save the listing in firebase storage. */
  const callAddListing = async (state) => {
    // Formatting the price to always be a decimal string so we can parse it on the client.
    let p = parseFloat(state.price, 10);
    let price = p.toFixed(2);
    state.price = price.toString();

    const saved = await addListing(location.category, state);
    const msg = saved === "SUCCESS" ? "Your listing has been saved!" : saved;

    setSubmitting(false);
    showSnackbar(msg, false);
  };

  /* Close all modals. */
  const closeModals = () => {
    setSnackbar(false);
  };

  /* Initiate the redirect timer, used for when operation is successful and user needs to go back. */
  const initRedirectTimer = () => {
    setTimeout(() => {
      navigateBack();
    }, 3000);
  };

  /* Send the user to the previous screen. */
  const navigateBack = () => {
    history.push({ pathname: `/listings/${projectId}` });
  };

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = (msg, redirect) => {
    setMessage(msg);
    setSnackbar(true);
    if (redirect) {
      initRedirectTimer();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <SideBar history={history} projectId={projectId} screen="Listings" />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header
          history={history}
          projectId={projectId}
          screen="Manage My Listings"
        />

        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            width: "100%",
            marginTop: 100,
          }}
        >
          <div style={{ marginBottom: 30, paddingLeft: 30, width: "100%" }}>
            <Button
              color="primary"
              onClick={history.goBack}
              variant="contained"
            >
              Back
            </Button>
          </div>

          {categories && categories.length > 0 && (
            <AddListingForm
              categories={categories}
              error={showSnackbar}
              submit={showLoadingProcess}
              submitting={submitting}
            />
          )}

          {/* Show a back button in the top left corner of the page. */}
          <div style={{ position: "fixed", left: 100, top: 80 }}>
            <GenericAddButton
              action={navigateBack}
              screen="listings"
              text="BACK"
            />
          </div>
          {/* Show modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
        </div>
      </div>
    </div>
  );
};
