import { useState } from "react";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { CustomAutoComplete, CustomTextField } from "../Custom/CustomForm";

export const SearchBar = ({ list, listType, overrideStyle, returnSearch }) => {
  const [search, setSearch] = useState("");

  /* Given a search term, get the search results. */
  const getSearchResults = (search) => {
    const tempArr = [];

    // If there is no search value.
    if (search?.length > 0) {
      returnSearch(list);
    } else if (typeof search === "object") {
      tempArr.push(search);
    } else if (listType === "Users") {
      list.forEach((el) => {
        const name = el.name.toLowerCase();
        const email = el.email.toLowerCase();

        if (
          name.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase())
        ) {
          tempArr.push(el);
        }
      });
    } else if (listType === "Listings") {
      list.forEach((el) => {
        const name = el.name.toLowerCase();
        const desc = el.description.toLowerCase();
        const price = el.price.toLowerCase();

        if (
          name.includes(search.toLowerCase()) ||
          desc.includes(search.toLowerCase()) ||
          price.includes(search.toLowerCase())
        ) {
          tempArr.push(el);
        }
      });
    } else if (listType === "Categories") {
      list.forEach((el) => {
        const name = el.name.toLowerCase();
        if (name.includes(search.toLowerCase())) {
          tempArr.push(el);
        }
      });
    } else if (listType === "Posts") {
      list.forEach((el) => {
        const body = el.body.toLowerCase();
        const title = el.title.toLowerCase();

        if (
          body.includes(search.toLowerCase()) ||
          title.includes(search.toLowerCase())
        ) {
          tempArr.push(el);
        }
      });
    } else if (listType === "Teammates") {
      list.forEach((el) => {
        const name = el.name.toLowerCase();

        if (name.includes(search.toLowerCase())) {
          tempArr.push(el);
        }
      });
    } else if (listType === "Rewards") {
      list.forEach((el) => {
        const body = el.body.toLowerCase();
        const points = el.points;
        const title = el.title.toLowerCase();

        if (
          body.includes(search.toLowerCase()) ||
          points.toString().includes(search.toLowerCase()) ||
          title.includes(search.toLowerCase())
        ) {
          tempArr.push(el);
        }
      });
    } else if (listType === "Promos") {
      list.forEach((el) => {
        const body = el.body.toLowerCase();
        const title = el.title.toLowerCase();

        if (
          body.includes(search.toLowerCase()) ||
          title.includes(search.toLowerCase())
        ) {
          tempArr.push(el);
        }
      });
    }

    returnSearch(tempArr);
  };

  /* Set option label of our autocomplete component. */
  const renderOptionLabels = (option) => {
    if (typeof option === "string") {
      return option;
    }
    if (listType === "Users") {
      return option.name + " - " + option.email;
    } else if (listType === "Listings") {
      const description =
        option.description.length > 30
          ? option.description.substr(0, 30) + "..."
          : option.description;
      return option.name + " - " + description + " - $" + option.price;
    } else if (listType === "Categories") {
      return option.name;
    } else if (listType === "Posts") {
      return option.title + " - " + option.body;
    } else if (listType === "Teammates") {
      return option.name;
    } else if (listType === "Rewards") {
      return option.title + " - " + option.body;
    } else if (listType === "Promos") {
      return option.title + " - " + option.body;
    }
  };

  /* Set search value based on the type of search this is. */
  const renderOptionTypes = (option) => {
    if (listType === "Users") {
      return option.name + " - " + option.email;
    } else if (listType === "Listings") {
      const description =
        option.description.length > 30
          ? option.description.substr(0, 30) + "..."
          : option.description;
      return option.name + " - " + description + " - $" + option.price;
    } else if (listType === "Categories") {
      return option.name;
    } else if (listType === "Posts") {
      return option.title + " - " + option.body;
    } else if (listType === "Teammates") {
      return option.name;
    } else if (listType === "Rewards") {
      return option.title + " - " + option.body;
    } else if (listType === "Promos") {
      return option.title + " - " + option.body;
    }
  };

  /* Search handler */
  const updateSearch = (_, newValue) => {
    setSearch(newValue);
    getSearchResults(newValue);
  };

  const filter = createFilterOptions();

  return (
    <CustomAutoComplete
      value={search}
      onChange={updateSearch}
      filterOptions={(options, params) => filter(options, params)}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={list}
      getOptionLabel={renderOptionLabels}
      renderOption={renderOptionTypes}
      freeSolo
      renderInput={(params) => (
        <CustomTextField
          {...params}
          placeholder="Search..."
          variant="outlined"
        />
      )}
      style={overrideStyle ? overrideStyle : { width: "80%" }}
    />
  );
};
