import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  GenericSnackbar,
  Header,
  SearchBar,
  SideBar,
} from '../../Components/Misc';
import { ConfirmDeleteModal } from '../../Components/Modals';
import { TeamTable } from '../../Components/Team';
import { getAllTeammembers, removeTeammember } from '../../Helpers/Data';
import { getFormattedProjectName } from '../../Helpers/Utils';

export const AllTeamMembers = ({ history }) => {
  const [filteredUsers, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [users, setUsers] = useState([]);
  const projectId = localStorage.getItem('appId');
  const helmetTitle = getFormattedProjectName();

  /* Used to call our getAllUsers function as fast as we can. */
  const callGetUsers = useCallback(async () => {
    const data = await getAllTeammembers();
    setLoading(false);
    setUsers(data);
  }, []);

  /* Get our list of users as fast as we can. */
  useEffect(() => {
    callGetUsers();
  }, [callGetUsers]);

  /* Call our removeTeammate function in Data.js */
  const callRemoveTeammate = async () => {
    const removed = await removeTeammember(selectedUser);

    // Find the teammate in our users array
    const temp = users.filter((x) => x?._id !== selectedUser);
    const shouldShow = removed === 'SUCCESS';
    const msg = shouldShow
      ? 'That Teammate has been removed.'
      : removed.message;
    showSnackbar(msg, shouldShow);

    setShowDeleteModal(false);
    if (shouldShow) {
      setUsers(temp);
    }
  };

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = (msg, redirect) => {
    setMessage(msg);
    setSnackbar(true);
  };

  /* Close all modals. */
  const closeModals = () => {
    setShowDeleteModal(false);
    setSelectedUser(null);
    setSnackbar(false);
  };

  /* Show a modal that asks the user if they really want to delete the user. */
  const confirmDeleteUser = (userId) => {
    setSelectedUser(userId);
    setShowDeleteModal(true);
  };

  /* Navigate to the user profile screen. */
  const handleNavigation = () => {
    history.push({ pathname: `/add-teammember/${projectId}` });
  };

  /* Given an array from our search bar, set the filterUsers array in state. */
  const setSearch = (items) => {
    setFiltered(items);
  };

  return (
    <>
      <Helmet title={`${helmetTitle} | Team`} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Team" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Header
            history={history}
            projectId={projectId}
            screen="Manage My Team"
          />

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
              marginTop: 100,
            }}
          >
            {!loading && users?.length < 1 && (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                There are currently no teammates.
              </div>
            )}

            {/* Search bar to display all users of our system. */}
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '80%',
              }}
            >
              <SearchBar
                list={users}
                listType="Teammates"
                returnSearch={setSearch}
              />
              <Button
                color="primary"
                variant="contained"
                style={{ height: '100%' }}
                onClick={handleNavigation}
              >
                Add Teammember
              </Button>
            </div>

            {users?.length > 0 && (
              <TeamTable
                confirmDelete={confirmDeleteUser}
                history={history}
                projectId={projectId}
                users={filteredUsers.length > 0 ? filteredUsers : users}
              />
            )}

            {/* Show conditionally rendered comps heree */}
            {snackbar && (
              <GenericSnackbar
                close={closeModals}
                message={message}
                open={snackbar}
              />
            )}
            {showDeleteModal && (
              <ConfirmDeleteModal
                category="teammate"
                close={closeModals}
                submit={callRemoveTeammate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
