import React, { useState } from "react";
import { editPromo, removePromo } from "../../Helpers/Data";
import { Button } from "@material-ui/core";

// Import Components
import {
  Header,
  GenericAddButton,
  GenericSnackbar,
  SideBar,
} from "../../Components/Misc";
import { EditPromoForm } from "../../Components/Promos";
import { ConfirmDeleteModal } from "../../Components/Modals";

export const EditPromo = ({ history, location }) => {
  const [message, setMessage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const projectId = localStorage.getItem("appId");

  /* Show some loading UI while we edit the document in firebase storage. */
  const showLoadingProcess = (state) => {
    setSubmitting(true);
    callEditPromo(state);
  };

  /* Find the document in Firestore and edit the appropriate fields. */
  const callEditPromo = async (state) => {
    // Parse through what's changed and what hasn't changed and save to the promo object.
    let promo = location.state;
    const statePromo = state;

    promo.body = statePromo.body ? statePromo.body : promo.body;
    promo.title = statePromo.title ? statePromo.title : promo.title;

    const edited = await editPromo(promo);

    const shouldShow = edited === "SUCCESS";
    const msg = shouldShow ? "Your promotion has been updated!" : edited;
    setSubmitting(false);
    showSnackbar(msg, shouldShow);
  };

  /* Close all modals. */
  const closeModals = () => {
    setShowDeleteModal(false);
    setSnackbar(false);
  };

  /* Initiate the redirect timer, used for when operation is successful and user needs to go back. */
  const initRedirectTimer = () => {
    setTimeout(() => {
      navigateBack();
    }, 3000);
  };

  /* Send the user to the previous screen. */
  const navigateBack = () => {
    history.push({ pathname: `/promos/${projectId}` });
  };

  /* Call our removePromo function in Data.js */
  const callRemovePromo = async () => {
    const promoId = location.state._id;
    const removed = await removePromo(promoId);
    const shouldShow = removed === "SUCCESS";
    const msg = shouldShow
      ? "Your promotion has been successfully deleted. Users cannot view it anymore."
      : removed;
    setShowDeleteModal(false);
    showSnackbar(msg, shouldShow);
  };

  /* Show modal that asks the user to confirm deleting a promo. */
  const showConfirmDeleteModal = () => {
    setShowDeleteModal(true);
  };

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = (msg, redirect) => {
    setMessage(msg);
    setSnackbar(true);
    if (redirect) {
      initRedirectTimer();
    }
  };

  const promo = location.state;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <SideBar history={history} projectId={projectId} screen="Promos" />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header
          history={history}
          projectId={projectId}
          screen="Manage My Promos"
        />

        <div
          style={{
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            maxWidth: 600,
            width: "100%",
            marginTop: 100,
          }}
        >
          <div style={{ marginBottom: 30, paddingLeft: 120, width: "100%" }}>
            <Button
              color="primary"
              onClick={history.goBack}
              variant="contained"
            >
              Back
            </Button>
          </div>
          <EditPromoForm
            confirmDelete={showConfirmDeleteModal}
            error={showSnackbar}
            promo={promo}
            submit={showLoadingProcess}
            submitting={submitting}
          />

          {/* Show a back button in the top left corner of the page. */}
          <div style={{ position: "fixed", left: 100, top: 80 }}>
            <GenericAddButton
              action={navigateBack}
              screen="promos"
              text="BACK"
            />
          </div>
          {/* Show modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
          {showDeleteModal && (
            <ConfirmDeleteModal
              category="promo"
              close={closeModals}
              submit={callRemovePromo}
            />
          )}
        </div>
      </div>
    </div>
  );
};
