import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { categoriesRef } from '../../Api/FirebaseConfigHandler';
import {
  AddCategoryForm,
  CategoriesTable,
  ManageModal,
} from '../../Components/Categories';
// Import components
import {
  GenericAddButton,
  GenericSnackbar,
  Header,
  SearchBar,
  SideBar,
} from '../../Components/Misc';
import {
  editCategory,
  getAllCategoriesData,
  removeCategory,
} from '../../Helpers/Data';
import { getFormattedProjectName } from '../../Helpers/Utils';

// Global variables
let unsubscribe;

export const ManageCategories = ({ history }) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFiltered] = useState([]);
  const [addcategorymodal, setAddCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modal, setModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const projectId = localStorage.getItem('appId');
  const helmetTitle = getFormattedProjectName();

  /* Get all the categories from our database and save to state. */
  const getData = useCallback(async () => {
    const data = await getAllCategoriesData();
    setCategories(data);
  }, []);

  /* Listen in our database for new categories to be added. */
  const listenForNewCategories = useCallback(async () => {
    unsubscribe = categoriesRef.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added' || change.type === 'modified') {
          setTimeout(() => {
            getData();
          }, 1000);
        }
      });
    });
  }, [getData]);

  /* Close all modals. */
  const closeModals = () => {
    setAddCategoryModal(false);
    setModal(false);
    setShowSnackbar(false);
  };

  /* Get all the categories from our database. */
  useEffect(() => {
    getData();
    listenForNewCategories();

    /* Detatch our database listener before comp unmount. */
    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [getData, listenForNewCategories]);

  /* Send the user to the previous screen. */
  const navigateBack = () => {
    history.push({ pathname: `/listings/${projectId}` });
  };

  /* Given an array from our search bar, set the filterUsers array in state. */
  const setSearch = (data) => {
    setFiltered(data);
  };

  /* Show some loading UI on the edit modal for a category. */
  const showLoading = (actionType, data) => {
    setLoading(true);
    updateCategory(actionType, data);
  };

  /* Show the edit modal for a given category. */
  const showModal = (data) => {
    setSelectedCategory(data);
    setModal(true);
  };

  /* Update the selected category in our database. */
  const updateCategory = async (actionType, category) => {
    if (actionType === 'Delete') {
      const deleted = await removeCategory(category);
      const msg =
        deleted === 'SUCCESS' ? 'That category has been deleted.' : deleted;

      cleanUpModal(msg);
    } else {
      const updated = await editCategory(category);
      const msg =
        updated === 'SUCCESS' ? 'Your category has been updated.' : updated;
      cleanUpModal(msg);
    }
  };

  /* Clean up our modals and state variables. */
  const cleanUpModal = (msg) => {
    setAddCategoryModal(false);
    setLoading(false);
    setMessage(msg);
    setModal(false);
    setSelectedCategory(null);
    setShowSnackbar(true);
  };

  /* Show the add-category modal. */
  const showAddCategoryModal = () => {
    setAddCategoryModal(true);
  };

  return (
    <>
      <Helmet title={`${helmetTitle} | Categories`} />
      <div
        style={{
          backgroundColor: '#e8e8e8',
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Listings" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Header
            history={history}
            projectId={projectId}
            screen="Manage My Categories"
          />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
              marginTop: 100,
            }}
          >
            <div
              style={{
                alignSelf: 'center',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 30,
                width: '80%',
              }}
            >
              <GenericAddButton
                action={navigateBack}
                screen="listings"
                text="BACK"
              />
              <Button
                color="primary"
                onClick={showAddCategoryModal}
                variant="contained"
              >
                Add New Category
              </Button>
            </div>
            {categories && categories.length < 0 && (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                There are currently no categories, you can add some by pressing
                the "Add" button below!
              </div>
            )}

            {/* Search bar to display all users of our system. */}
            <SearchBar
              list={categories}
              listType="Categories"
              returnSearch={setSearch}
            />

            {categories && categories.length > 0 && (
              <CategoriesTable
                history={history}
                projectId={projectId}
                categories={
                  filteredCategories.length > 0
                    ? filteredCategories
                    : categories
                }
                showModal={showModal}
              />
            )}

            {/* Show conditionally rendered comps heree */}
            {showSnackbar && (
              <GenericSnackbar
                close={closeModals}
                message={message}
                open={showSnackbar}
              />
            )}
            {modal && (
              <ManageModal
                category={selectedCategory}
                close={closeModals}
                loading={loading}
                open={modal}
                submit={showLoading}
              />
            )}
            {addcategorymodal && (
              <AddCategoryForm
                close={closeModals}
                error={cleanUpModal}
                success={cleanUpModal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
