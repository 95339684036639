import { Backdrop, Fade, Modal, Paper } from '@material-ui/core';
import React from 'react';
import { styles } from '../../Styles/Misc/GenericModal';
import { CustomButton } from '../Custom/CustomForm';

export const GenericModal = ({ close, text }) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    style={styles.modal}
    open={true}
    onClose={close}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={true}>
      <Paper elevation={3} style={styles.paper}>
        <h3 style={styles.subheader}>{text}</h3>
        <hr style={styles.progressbar} />
        <div style={styles.buttoncontainer}>
          <CustomButton
            variant="contained"
            color="default"
            style={styles.centerbutton}
            onClick={close}
          >
            Close
          </CustomButton>
        </div>
      </Paper>
    </Fade>
  </Modal>
);
