import { AppBar, Button, Toolbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import useAuth from '../../Hooks/useAuth';
import { colors } from '../../Styles/Colors';

/* Our basic header component for the site. Only should have an Avatar for the authed
   user to access their account with. */
export const Header = (props) => {
  const { user } = useAuth();
  const { history, projectId, screen } = props;

  const navigateUser = () => {
    history.push({
      pathname: `/my-profile/${projectId}`,
      user,
    });
  };

  return (
    <AppBar
      position="relative"
      style={{ backgroundColor: '#FFF', width: '100%' }}
    >
      <Toolbar
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
        }}
      >
        <Button color="primary" onClick={navigateUser}>
          <p style={{ fontWeight: 'bold' }}>My Account</p>
        </Button>
        <h2 style={{ color: colors.moss }}>{screen}</h2>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  history: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  screen: PropTypes.string.isRequired,
};
