export const colors = {
  primary: '#90CAF9',
  secondary: '#F48FB1',
  moss: '#3ABB94',
  aqua: '#224091',
  black: '#121212',
  darkgray: '#4F4F4F',
  gray1: '#828282',
  gray2: '#BDBDBD',
  gray3: '#E0E0E0',
  gray4: '#F2F2F2',
  white: '#FFFFFF',
  red: '#BB3A3A',
  purple: '#9a3aaf',
  deepblack: '#1e1e1e',
};
