import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { storageRef } from '../../Api/FirebaseConfigHandler';
// Import components
import {
  CustomButton,
  CustomSelect,
  CustomTextField,
} from '../../Components/Custom/CustomForm';
import useAuth from '../../Hooks/useAuth';
import { ImageModal } from '../Misc';

export const EditListingForm = ({
  categories,
  confirmDelete,
  error,
  listing,
  submit,
  submitting,
}) => {
  const { user } = useAuth();
  // const [category, setCategory] = useState(listing.category);
  // const [description, setDescription] = useState(listing.description);
  // const [featured, setFeatured] = useState(listing.featured);
  // const [hidden, setHidden] = useState(listing.hidden);
  const [imageName, setImageName] = useState(listing.imageName);
  const [loading, setLoading] = useState(false);
  // const [name, setName] = useState(listing.name);
  // const [price, setPrice] = useState(listing.price);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState(listing.url);

  /* Close the image modal. */
  const closeModals = () => {
    setShowModal(false);
  };

  /* Show some loading animation while the image is processing. */
  const handlePictureUpload = (files) => {
    setLoading(true);
    uploadPicture(files);
  };

  /* Check to see if the authenticated user is allowed to change a listing or delete it. */
  const isDisabled = (action, submitting) => {
    if (submitting) {
      return true;
    } else if (
      (user.role === 'Manager' && action !== 'DELETE') ||
      user.role === 'Admin'
    ) {
      return false;
    } else {
      return true;
    }
  };

  /* Upload the image blob to firebase storage then save the download url to our listing object. */
  const uploadPicture = async (files) => {
    try {
      // We only want 1 image to be uploaded
      const image = files[0];

      const ref = storageRef.child(`/Listings/${image.name}`);

      const downloadUrl = await ref.put(image).then(async () => {
        return await ref.getDownloadURL();
      });

      setImageName(image.name);
      setUrl(downloadUrl);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      error(error);
    }
  };

  /* Show modal to blow up image with onclick. */
  const enhanceImage = () => {
    setShowModal(true);
  };

  // /* Change the selected category in the select. */
  // const handleCategoryChange = (e) => {
  //   setCategory(e.target.value);
  // };

  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        padding: 40,
      }}
    >
      <h3 style={{ textAlign: 'center' }}>Edit This Listing</h3>
      <Formik
        initialValues={{
          category: listing.category,
          description: listing.description,
          featured: listing.featured !== undefined ? listing.featured : false,
          hidden: listing.hidden,
          name: listing.name,
          price: listing.price,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          category: Yup.string()
            .min(4)
            .max(255)
            .required('Please give this listing a category.'),
          description: Yup.string().required(
            'Please give this listing a description.'
          ),
          featured: Yup.boolean(),
          hidden: Yup.boolean(),
          name: Yup.string()
            .min(4)
            .max(255)
            .required('Please give this listing a name.'),
          price: Yup.number()
            .min(0.01)
            .required('Please give a price to this listing.'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // Do some last minute validation here.

            // Send perfect request.
            let st = values;
            st.imageName = imageName;
            st.url = url;

            submit(st);

            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error('CAUGHT ERROR:', err);

            setStatus({ success: false });
            setErrors({ submit: err });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label>Name</label>
            <CustomTextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{ mb: 2 }}
              value={values.name}
              variant="outlined"
            />
            <label style={{ marginTop: 20 }}>Description</label>
            <CustomTextField
              error={Boolean(touched.description && errors.description)}
              fullWidth
              multiline={true}
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              rows={8}
              value={values.description}
              variant="outlined"
            />
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 10,
                marginTop: 20,
              }}
            >
              <img
                alt="listing-pic"
                src={url}
                onClick={enhanceImage}
                style={{
                  borderRadius: 10,
                  cursor: 'pointer',
                  height: '100%',
                  maxWidth: 400,
                  width: '100%',
                }}
              />
            </Box>
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
              sx={{ mb: 2 }}
            >
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{
                  backgroundColor: '#424242',
                  display: 'none',
                  width: '100%',
                }}
                onChange={(e) => handlePictureUpload(e.target.files)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  color="primary"
                  component="span"
                  disabled={isDisabled('UPLOAD', submitting)}
                  variant="contained"
                  style={{ fontWeight: 'bold' }}
                >
                  Replace Image
                </Button>
              </label>
              {loading && (
                <CircularProgress color="primary" style={{ marginLeft: 20 }} />
              )}
            </Box>
            <FormControl margin="normal" size="medium" variant="outlined">
              <label>Category</label>
              <CustomSelect
                error={Boolean(touched.category && errors.category)}
                name="category"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
                variant="outlined"
              >
                {categories.map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.featured}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="featured"
                  onChange={handleChange}
                />
              }
              label="Is this listing featured?"
              style={{ marginBottom: 10, marginTop: 10 }}
            />
            {/* <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
        }}
      >
        <Checkbox
          checked={hidden !== undefined ? hidden : false}
          onChange={(e) => this.setState({ hidden: e.target.checked })}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <p>Is this listing hidden?</p>
      </div> */}
            <label>Price</label>
            <CustomTextField
              error={Boolean(touched.price && errors.price)}
              fullWidth
              name="price"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.price}
              variant="outlined"
            />
            <hr style={{ width: '100%' }} />
            {submitting && (
              <LinearProgress
                color="primary"
                style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
              />
            )}
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              sx={{ ml: 2, mr: 2 }}
            >
              <Button
                color="secondary"
                disabled={isDisabled('DELETE', submitting)}
                onClick={confirmDelete}
                style={{ marginRight: 10, width: '100%' }}
                variant="contained"
              >
                DELETE
              </Button>
              <CustomButton
                color="primary"
                disabled={isSubmitting}
                style={{ width: '100%' }}
                type="submit"
                variant="contained"
              >
                SUBMIT
              </CustomButton>
              {showModal && (
                <ImageModal close={closeModals} imgUrl={url} open={showModal} />
              )}
            </Box>
            <Box style={{ marginTop: 10 }}>
              {errors.submit && (
                <Alert severity="error">
                  <div>{errors.submit}</div>
                </Alert>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

EditListingForm.propTypes = {
  categories: PropTypes.array.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};
