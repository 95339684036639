import React from 'react';

export const NoListingsText = () => (
  <div
    style={{
      alignItems: 'center',
      alignSelf: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      marginTop: 30,
      textAlign: 'center',
      width: '100%',
    }}
  >
    There are currently no listings, please click on the button next to the
    category dropdown to add some!
  </div>
);
