import { Button, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/icons/Menu';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { useState } from 'react';

export const ListingRowItem = ({
  action,
  enhanceImage,
  listing,
  toggleFeatured,
}) => {
  const classes = useStyles();
  const [featured, setFeatured] = useState(listing.featured);

  const updateFeatured = () => {
    setFeatured(!listing.featured);
    listing.featured = !listing.featured;
    toggleFeatured(listing);
  };

  // thumbnail, name, description (up to 30 chars?), price
  return (
    <div className={classes.root}>
      <div className={classes.imagecontainer}>
        <img
          alt={listing.imageName}
          className={classes.image}
          onClick={() => enhanceImage(listing.url)}
          src={listing.url}
        />
      </div>
      <Tooltip
        title={
          <h1>
            {listing.featured ? 'Remove from featured' : 'Feature this listing'}
          </h1>
        }
      >
        {/* For the toggle featured feature. */}
        <IconButton
          aria-label="more"
          aria-controls="menu-open"
          aria-haspopup="true"
          color="primary"
          onClick={updateFeatured}
        >
          {featured && <Star />}
          {!featured && <StarBorder />}
        </IconButton>
      </Tooltip>
      <p className={classes.title}>{listing.name}</p>
      <p className={classes.description}>
        {listing.description.length > 30
          ? listing.description.substr(0, 30) + '...'
          : listing.description}
      </p>
      <p className={classes.price}>${listing.price}</p>
      <Button
        color="primary"
        className={classes.button}
        onClick={() => action(listing)}
        variant="contained"
      >
        Manage
      </Button>
      <Menu fontSize="large" />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: '#C8c8c8',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: 10,
    width: '102.5%',
    minWidth: 300,
    borderColor: '#3366FF',
    borderRadius: 5,
    borderWidth: 1,
    marginLeft: -40,
    marginRight: -60
  },
  actions: {
    justifyContent: 'center',
  },
  button: {
    marginLeft: 15,
  },
  buttontext: {
    fontWeight: 'bold',
  },
  description: {
    fontSize: 15,
    margin: 4,
    maxWidth: 200,
    width: 200,
  },
  header: {
    alignItems: 'center',
    backgroundColor: '#F4F6FA',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  image: {
    borderRadius: 10,
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  imagecontainer: {
    margin: 4,
    maxWidth: 100,
    minWidth: 50,
  },
  price: {
    fontSize: 15,
    margin: 4,
  },
  title: {
    fontSize: 17,
    margin: 4,
    maxWidth: 200,
    width: 200,
  },
});
