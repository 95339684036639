export const styles = {
  modal: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  paper: {
    padding: 30,
    maxWidth: 400,
  },
  subheader: {
    textAlign: 'center',
  },
  progressbar: {
    width: '100%',
  },
  textfield: {
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
  },
  buttoncontainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  leftbutton: {
    marginRight: 20,
    width: '100%',
  },
  rightbutton: {
    width: '100%',
  },
};
