import React from 'react';
import { logo } from '../../Api/FirebaseConfigHandler';
export const MobileWarning = () => (
  <div
    style={{
      alignItems: 'center',
      backgroundColor: '#FFF',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      minHeight: '100vh',
      width: '100%',
    }}
  >
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <img alt="mobile-warning.png" src={logo} width="80%" />
      <h1 style={{ textAlign: 'center' }}>
        Please use a laptop or desktop computer to visit this site.
      </h1>
    </div>
  </div>
);
