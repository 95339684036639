import {
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import {
  CustomSelect,
  CustomTextField,
} from '../../Components/Custom/CustomForm';
import {
  GenericAddButton,
  GenericModal,
  GenericSnackbar,
  Header,
  SideBar,
} from '../../Components/Misc';
import { updateUser } from '../../Helpers/Data';
import en from '../../Helpers/Text/en.json';
import { getFormattedProjectName } from '../../Helpers/Utils';
import useAuth from '../../Hooks/useAuth';
import { styles } from '../../Styles/Users/UserProfile';

/*
 * Shows the selected user's information with editable fields.
 */
export const UserProfile = ({ history, location }) => {
  const myInfo = useAuth().user;
  const projectId = localStorage.getItem('appId');
  const [message, setMessage] = useState('');
  const [mode, setMode] = useState('view');
  const [selectedRole, setSelectedRole] = useState(location.state.role);
  const [helpModal, setShowHelpModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const user = location.state;
  const helmetTitle = getFormattedProjectName();

  /* Save a message and show the snackbar. */
  const showWarning = (msg) => {
    setMessage(msg);
    setSnackbar(true);
  };

  /* 
     Check to see which mode we are in. 
     If edit, return true show the submit button at the bottom of the screen.
     If view, the edit button is not disabled at the bottom of the screen. 
  */
  const checkMode = (mode) => {
    return mode === 'edit' ? false : true;
  };

  /* Close all modals. */
  const closeModals = () => {
    setShowHelpModal(false);
    setSnackbar(false);
  };

  /* Handle the back button click. */
  const handleBackButtonClick = () => {
    history.push({ pathname: `/users/${projectId}` });
  };

  /* Used to handle the change in role for a given user. */
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  /* Based on the authed user's role, return the roles they can up/downgrade a user to. */
  const returnAvailableRoles = () => {
    const myRole = myInfo.role;
    if (myRole === 'Admin') {
      return [
        <MenuItem key={0} value="User">
          User
        </MenuItem>,
        <MenuItem key={1} value="Employee">
          Employee
        </MenuItem>,
        <MenuItem key={2} value="Manager">
          Manager
        </MenuItem>,
        <MenuItem key={3} value="Admin">
          Admin
        </MenuItem>,
      ];
    } else if (myRole === 'Manager') {
      return [
        <MenuItem key={0} value="User">
          User
        </MenuItem>,
        <MenuItem key={1} value="Employee">
          Employee
        </MenuItem>,
        <MenuItem key={2} value="Manager">
          Manager
        </MenuItem>,
      ];
    } else if (myRole === 'Employee') {
      return [
        <MenuItem key={0} value="User">
          User
        </MenuItem>,
        <MenuItem key={1} value="Employee">
          Employee
        </MenuItem>,
      ];
    }
  };

  /* Helper function to display the help modal on this screen. */
  const showHelpModal = () => {
    setShowHelpModal(true);
  };

  /* Set the mode to edit. */
  const registerEditMode = () => {
    setMode('edit');
  };

  const helmetText = user && user.name + "'s" ? user.name : '';

  return (
    <>
      <Helmet title={`${helmetTitle} | ${helmetText} Profile`} />
      <div style={styles.screencontainer}>
        <SideBar history={history} projectId={projectId} screen="Users" />
        <div style={styles.headercontainer}>
          <Header
            history={history}
            projectId={projectId}
            screen="User's Account"
          />
          <Fade in={true} timeout={{ enter: 1000 }}>
            <div style={styles.maincontainer}>
              <div style={styles.contentcontainer}>
                <div style={styles.backbuttoncontainer}>
                  <Button
                    color="primary"
                    onClick={handleBackButtonClick}
                    style={styles.boldfont}
                    variant="outlined"
                  >
                    Back
                  </Button>
                </div>
                <Formik
                  initialValues={{
                    email: location.state.email,
                    name: location.state.name,
                    phonenumber: location.state.phonenumber,
                    PIN: location.state.PIN,
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email(),
                    name: Yup.string(),
                    phonenumber: Yup.string(),
                    PIN: Yup.string(),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      // Do some last minute validation here.

                      // Send perfect request. Uncomment the imagename and url assignments if we change to image support for posts.
                      if (
                        selectedRole !== 'User' &&
                        (!values.PIN || !values.phonenumber)
                      ) {
                        // eslint-disable-next-line no-new-wrappers
                        throw new String(
                          'Please input a pin and phone number for this user.'
                        );
                      }

                      const updated = await updateUser(
                        values.phonenumber,
                        values.PIN,
                        selectedRole,
                        user._id
                      );

                      // setLoading(false);
                      setMode('view');

                      const msg = `Success! ${user.name}'s account has been updated!`;

                      updated === 'SUCCESS'
                        ? showWarning(msg)
                        : showWarning(updated);

                      setStatus({ success: true });
                      setSubmitting(false);
                    } catch (err) {
                      setStatus({ success: false });
                      setErrors({ submit: err });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit} style={styles.form}>
                      <div style={styles.accountcontentmetaheader}>
                        <h1 style={styles.formheader}>{user.name}</h1>
                        <GenericAddButton
                          action={showHelpModal}
                          screen="user-profile"
                          text="HELP"
                        />
                      </div>
                      <h3 style={styles.formsubheader}>{user.role}</h3>
                      <hr style={styles.formbreakline} />

                      <InputLabel style={styles.label}>Display Name</InputLabel>
                      <CustomTextField
                        disabled
                        error={Boolean(touched.name && errors.name)}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={values.name}
                        style={styles.usertextinput}
                        value={values.name}
                        variant="outlined"
                      />
                      {selectedRole !== 'User' && (
                        <>
                          {selectedRole !== 'User' && (
                            <>
                              <InputLabel style={styles.label}>PIN</InputLabel>
                              <CustomTextField
                                disabled={checkMode(mode)}
                                error={Boolean(touched.PIN && errors.PIN)}
                                inputProps={{ maxLength: 4, minLength: 4 }}
                                name="PIN"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={values.PIN}
                                style={styles.usertextinput}
                                value={values.PIN}
                                variant="outlined"
                              />
                            </>
                          )}

                          <InputLabel style={styles.label}>
                            Phone Number
                          </InputLabel>
                          <CustomTextField
                            disabled={checkMode(mode)}
                            error={Boolean(
                              touched.phonenumber && errors.phonenumber
                            )}
                            InputProps={{
                              inputComponent: PhoneNumberInputFormat,
                            }}
                            name="phonenumber"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={values.phonenumber}
                            style={styles.usertextinput}
                            value={values.phonenumber}
                            variant="outlined"
                          />
                          <InputLabel style={styles.label}>Email</InputLabel>
                          <CustomTextField
                            disabled
                            error={Boolean(touched.email && errors.email)}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={values.email}
                            style={styles.usertextinput}
                            value={values.email}
                            variant="outlined"
                          />
                        </>
                      )}

                      {/* Logic for upgrading and degrading a user */}
                      <InputLabel style={styles.label}>Role</InputLabel>
                      <FormControl style={{ width: '100%' }} variant="outlined">
                        <CustomSelect
                          disabled={mode === 'view'}
                          onChange={handleRoleChange}
                          value={selectedRole}
                        >
                          {returnAvailableRoles()}
                        </CustomSelect>
                      </FormControl>

                      <hr style={styles.fullwidth} />

                      {mode === 'view' && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={registerEditMode}
                        >
                          Edit
                        </Button>
                      )}
                      {mode === 'edit' && (
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={
                            (myInfo.role !== 'Admin' &&
                              user.role === 'Admin') ||
                            (myInfo.role === 'Employee' &&
                              user.role === 'Manager') ||
                            (myInfo.role === 'Manager' &&
                              user.role === 'Manager')
                          }
                        >
                          Submit
                        </Button>
                      )}
                      <Box style={{ marginTop: 10 }}>
                        {errors.submit && (
                          <Alert severity="error">
                            <div>{errors.submit}</div>
                          </Alert>
                        )}
                      </Box>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </Fade>
        </div>

        {/* Show event modals down here. */}
        {snackbar && (
          <GenericSnackbar
            close={closeModals}
            message={message}
            open={snackbar}
          />
        )}
        {helpModal && (
          <GenericModal close={closeModals} text={en.help.user_profile} />
        )}
      </div>
    </>
  );
};

const PhoneNumberInputFormat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
    />
  );
};

PhoneNumberInputFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
