import { createContext, useEffect, useState } from "react";
import { auth } from "../Api/FirebaseConfigHandler";
import { getUser } from "../Helpers/Data";

const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
};

const AuthContext = createContext({
  ...initialState,
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});

  /* Get the signed in user's data and save it to context. */
  useEffect(() => {
    /* Get the user's data. */
    const callGetData = async (userId) => {
      const ud = await getUser(userId);
      setUser(ud);
    };

    /* Subscribe to auth events. */
    const unsub = auth.onAuthStateChanged((userObj) => {
      if (userObj) {
        callGetData(userObj.uid);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
