/**
 * Sends back the customer name based on a firebase project name.
 */
export const getFormattedProjectName = () => {
  let siteCode = window.location.search;

  if (siteCode.length === 0) {
    siteCode = window.location.pathname.split('$')[1];
    siteCode = '?' + siteCode;
  }

  const firebaseId = siteCode === '?undefined' ? '?HGrille' : siteCode;

  let pageName = 'Hudsonville Grille';
  switch (firebaseId) {
    case '?HGrille':
      pageName = 'Hudsonville Grille';
      break;
    case '?Authoritek-Testing':
      pageName = 'Authoritek Portal';
      break;
    case '?317-Coffee':
      pageName = '317 Coffee';
      break;
    case '?BillingPortal':
      pageName = 'Billing Portal';
      break;
    case '?Saugatuck-Schools':
      pageName = 'Saugatuck Schools';
      break;
    case '?Frankenmuth-Schools':
      pageName = 'Frankenmuth Schools';
      break;
    case '?Man-Cave':
      pageName = 'Man Cave';
      break;
    case '?Random':
      pageName = 'Random';
      break;
    case '?Townline-Hatchery':
      pageName = 'Townline Hatchery';
      break;
    default:
      pageName = 'Authoritek Portal';
      break;
  }

  return pageName;
};
