import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toggleListingFeatured } from '../../Helpers/Data';
import { ListingRowItem } from './ListingRowItem';

export const ListingsOrganizer = ({
  enhanceImage,
  history,
  list,
  showSnackbar,
  updateListings
}) => {
  const [listings, setListings] = useState(list);
  const projectId = localStorage.getItem('appId');

  /* When changes to props happen, update our list. */
  useEffect(() => {
      setListings(list);
  }, [list]);

  /* Handler for viewing a listing's full data. */
  const viewListing = (listing) => {
    history.push({ pathname: `/edit-listing/${projectId}`, state: listing });
  };

  /* Actually change the location of each list item. */
  const handleOnDragEnd = (result) => {
    const items = Array.from(listings);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const res = items.map((el, index) => {
      return {
        ...el,
        order: index
      }
      })

    updateListings(res)
  };

  /* Toggle the listing as featured or not in Firestore. */
  const handleToggleFeatured = async (item) => {
    const toggled = await toggleListingFeatured(item);
    showSnackbar(toggled === 'SUCCESS', item);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="listings">
        {(provided) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              alignSelf: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              listStyle: 'none',
              listStyleType: 'none',
              padding: 0,
              marginLeft: -50,
              marginRight: -50,
              width: '100%',
            }}
          >
            {listings.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ListingRowItem
                      action={viewListing}
                      enhanceImage={enhanceImage}
                      key={item._id}
                      listing={item}
                      toggleFeatured={handleToggleFeatured}
                    />
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};
