import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { promosRef } from '../../Api/FirebaseConfigHandler';
// Import components
import { Header, SearchBar, SideBar } from '../../Components/Misc';
import {
  HowToPromos,
  NoPromosText,
  PromosTable,
} from '../../Components/Promos';
import { getFormattedProjectName } from '../../Helpers/Utils';

// Global variables
let observer;

export const AllPromos = ({ history }) => {
  const [filteredItems, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promos, setPromos] = useState([]);
  const projectId = localStorage.getItem('appId');
  const helmetTitle = getFormattedProjectName();

  /* Data request handler for this component. Calls firestore for all promos.
     Should probably show these in order of most recent to the top with only 20 at a time. */
  const getData = useCallback(() => {
    let temparr = [];
    observer = promosRef.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added' && !change.doc.data().hidden) {
          let Obj = change.doc.data();
          Obj.createdAt = Obj.createdAt ? Obj.createdAt._seconds : 'Just Now';

          temparr.push(Obj);
        }

        temparr = temparr.sort((a, b) => {
          return b.createdAt - a.createdAt;
        });

        setLoading(false);
        setPromos(temparr);
      });
    });
  }, []);

  /* Get company promos when the screen initially renders */
  useEffect(() => {
    getData();

    return () => {
      /* Stop listening for changes on our snapshot listener. */
      if (typeof observer === 'function') {
        observer();
      }
    };
  }, [getData]);

  /* Given an array from our search bar, set the filterUsers array in state. */
  const setSearch = (items) => {
    setFiltered(items);
  };

  /* Navigate to the "Add-post" screen. */
  const handleNavigation = () => {
    history.push({ pathname: `/add-promo/${projectId}` });
  };

  return (
    <>
      <Helmet title={`${helmetTitle} | Promos`} />
      <div
        style={{
          backgroundColor: '#e8e8e8',
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Promos" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Header
            history={history}
            projectId={projectId}
            screen="Manage My Promos"
          />

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <HowToPromos />

            <div
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: 1000,
                width: '85%',
              }}
            >
              {/* Search bar to display all users of our system. */}
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <SearchBar
                  list={promos}
                  listType="Promos"
                  returnSearch={setSearch}
                />
                <Button
                  color="primary"
                  onClick={handleNavigation}
                  style={{ marginLeft: 20 }}
                  variant="contained"
                >
                  Add New Promo
                </Button>
              </div>

              {!loading && promos && promos.length < 1 && <NoPromosText />}

              {promos && promos.length > 0 && (
                <PromosTable
                  history={history}
                  projectId={projectId}
                  promos={filteredItems.length > 0 ? filteredItems : promos}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
