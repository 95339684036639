/* eslint-disable eqeqeq */
import { Avatar } from '@material-ui/core';
import {
  Cake,
  Dashboard,
  Group,
  Info,
  LocalAtm,
  MenuBook,
  NotificationsActive,
  PeopleOutline,
  Redeem,
} from '@material-ui/icons';
import React from 'react';
import { Menu, MenuItem, ProSidebar, SidebarHeader } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { logo } from '../../Api/FirebaseConfigHandler';
import useAuth from '../../Hooks/useAuth';
import { colors } from '../../Styles/Colors';

export const SideBar = ({ history, screen }) => {
  const { user } = useAuth();
  /* Render Admin header options */
  const renderHeaderOptions = () => {
    if (!user) {
      return;
    }
    const role = user.role;
    const projectId = localStorage.getItem('appId');

    let BIRTHDAYS = localStorage.getItem('BIRTHDAYS');
    let LISTINGS = localStorage.getItem('LISTINGS');
    let POSTS = localStorage.getItem('POSTS');
    let PROMOS = localStorage.getItem('PROMOS');
    let REWARDS = localStorage.getItem('REWARDS');
    let TEAM = localStorage.getItem('TEAM');
    let USERS = localStorage.getItem('USERS');

    BIRTHDAYS = BIRTHDAYS == 'true';
    LISTINGS = LISTINGS == 'true';
    POSTS = POSTS == 'true';
    PROMOS = PROMOS == 'true';
    REWARDS = REWARDS == 'true';
    TEAM = TEAM == 'true';
    USERS = USERS == 'true';

    const staffALL = [
      <MenuItem
        icon={<Dashboard />}
        key={0}
        onClick={() => history.push({ pathname: `/dashboard/${projectId}` })}
      >
        <p
          style={
            screen === 'Dashboard'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          Dashboard
        </p>
      </MenuItem>,
      <MenuItem
        icon={<Group />}
        key={1}
        onClick={() => history.push({ pathname: `/users/${projectId}` })}
      >
        <p
          style={
            screen === 'Users'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          Users
        </p>
      </MenuItem>,
      <MenuItem
        icon={<PeopleOutline />}
        key={1}
        onClick={() => history.push({ pathname: `/team/${projectId}` })}
      >
        <p
          style={
            screen === 'Team'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          Team
        </p>
      </MenuItem>,
      <MenuItem
        icon={<MenuBook />}
        key={2}
        onClick={() => history.push({ pathname: `/listings/${projectId}` })}
      >
        <p
          style={
            screen === 'Listings'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          Listings
        </p>
      </MenuItem>,
      <MenuItem
        icon={<NotificationsActive />}
        key={3}
        onClick={() => history.push({ pathname: `/posts/${projectId}` })}
      >
        <p
          style={
            screen === 'Posts'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          Posts
        </p>
      </MenuItem>,
      <MenuItem
        icon={<Redeem />}
        key={3}
        onClick={() => history.push({ pathname: `/promos/${projectId}` })}
      >
        <p
          style={
            screen === 'Promos'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          Promos
        </p>
      </MenuItem>,
      <MenuItem
        icon={<Cake />}
        key={4}
        onClick={() => history.push({ pathname: `/birthdays/${projectId}` })}
      >
        <p
          style={
            screen === 'Birthday'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          Birthdays
        </p>
      </MenuItem>,
      <MenuItem
        icon={<LocalAtm />}
        key={4}
        onClick={() => history.push({ pathname: `/rewards/${projectId}` })}
      >
        <p
          style={
            screen === 'Rewards'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          Rewards
        </p>
      </MenuItem>,
      <MenuItem
        icon={<Info />}
        key={5}
        onClick={() => history.push({ pathname: `/more/${projectId}` })}
      >
        <p
          style={
            screen === 'More'
              ? {
                  color: colors.moss,
                  fontWeight: 'bold',
                  marginTop: 0,
                  marginBottom: 0,
                }
              : { marginTop: 0, marginBottom: 0 }
          }
        >
          More
        </p>
      </MenuItem>,
    ];

    if (role === 'Admin' || role === 'Manager' || role === 'Employee') {
      let temp = [staffALL[0]];

      // [Dashboard, Users, Team, Listings, Posts, Promos, Birthdays, More]
      if (USERS) {
        temp.push(staffALL[1]);
      }
      if (TEAM) {
        temp.push(staffALL[2]);
      }
      if (LISTINGS) {
        temp.push(staffALL[3]);
      }
      if (POSTS) {
        temp.push(staffALL[4]);
      }
      if (PROMOS) {
        temp.push(staffALL[5]);
      }
      if (BIRTHDAYS) {
        temp.push(staffALL[6]);
      }
      if (REWARDS) {
        temp.push(staffALL[7]);
      }

      temp.push(staffALL[8]);

      return temp;
    } else {
      return [
        <MenuItem
          icon={<Info />}
          key={5}
          onClick={() => history.push({ pathname: `/more/${projectId}` })}
        >
          <p
            style={
              screen === 'More'
                ? {
                    color: colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            More
          </p>
        </MenuItem>,
      ];
    }
  };

  // /* Render the user's name after we know they're signed in. */
  // renderName() {
  //   auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       this.setState({ name: user.displayName });
  //     }
  //   });
  // }

  return (
    <div key="side-bar-container">
      <ProSidebar key="pro-side-bar">
        <SidebarHeader
          key="sidebar-header"
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 15,
            paddingBottom: 15,
          }}
        >
          <Avatar
            alt="Portal-Logo"
            key="avatar"
            src={logo}
            style={{ backgroundColor: '#FFF' }}
          />
        </SidebarHeader>
        <Menu key="menu">{renderHeaderOptions()}</Menu>
      </ProSidebar>
    </div>
  );
};
