import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { AddCategoryForm } from '../Categories';
import { CustomSelect } from '../Custom/CustomForm';
import { GenericSnackbar, SearchBar } from '../Misc';

/* Ideally this is a stateless component, it only passes the inputted list from listings into the searchbar 
   and they communicate with each other. */
export const ListingTableHeader = ({
  categories,
  changeCategory,
  listings,
  reloadCategories,
  saveListings,
  sendToAddModal,
  setSearch,
  showFeatured,
  showSnackbar,
  toggleFeatured,
}) => {
  const [selectedListings, setSelectedListings] = useState(listings);
  const [category, setCategory] = useState(categories[0]);
  const [message, setMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  useEffect(() => {
    setSelectedListings(listings);
  }, [listings]);

  /* Close modals */
  const closeModals = () => {
    setShowModal(false);
    setSnackbar(false);
  };

  /* Handle changes to the category. */
  const handleCategoryChange = (category) => {
    setCategory(category.target.value);
    changeCategory(category.target.value);
  };

  /* Handle the action of selecting a button from the dropdown. */
  const handleActionSelect = (action) => {
    if (action === 'Add New Category') {
      setShowModal(true);
    } else if (action === 'Add New Listing') {
      sendToAddModal();
    } else if (action === 'Save Listings') {
      saveListings();
    } else if (action === 'Help') {
      showSnackbar();
    }
  };

  /* Show error snackbar is operation fails. */
  const showError = (error) => {
    setMessage(error);
    setShowModal(false);
    setSnackbar(true);
  };

  /* Show success snackbar if operation is successful. */
  const showSuccess = () => {
    const m = 'Success! Your category has been added.';
    setMessage(m);
    setShowModal(false);
    setSnackbar(true);
    reloadCategories();
  };

  /* Our main render method of this component. */
  return (
    <div
      style={{
        backgroundColor: '#C8c8c8',
        borderRadius: 10,
        borderWidth: 2,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
        width: '100%',
      }}
    >
      {/* Ask admin to save changes to the listings ordering. */}

      {/* Search all listings. */}
      <SearchBar
        list={selectedListings}
        listType="Listings"
        overrideStyle={{ width: '50%' }}
        returnSearch={setSearch}
      />
      {/* Swap categories. */}
      <div style={{ marginLeft: 20, marginRight: 5 }}>
        <FormControl variant="outlined">
          <CustomSelect
            defaultValue={categories[0]}
            value={category ? category : categories[0]}
            onChange={handleCategoryChange}
          >
            {categories.map((cat, index) => (
              <MenuItem key={index} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
      </div>
      {/* Button to toggle show only favorited items. */}
      <Tooltip title={<h1>Toggle featured items</h1>}>
        {/* For the toggle featured feature. */}
        <IconButton
          aria-label="more"
          aria-controls="menu-open"
          aria-haspopup="true"
          color="primary"
          onClick={() => toggleFeatured(category, selectedListings)}
        >
          {showFeatured ? <Star /> : <StarBorder />}
        </IconButton>
      </Tooltip>
      {/* Help Button here */}
      <div style={{ marginRight: 5 }}>
        <IconButton
          aria-label="Help"
          aria-controls="Help-open"
          aria-haspopup="true"
          color="primary"
          onClick={() => handleActionSelect('Help')}
        >
          <Help />
        </IconButton>
      </div>
      {/* Add Listing Button */}
      <div>
        <Button
          color="primary"
          onClick={() => handleActionSelect('Add New Listing')}
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
          variant="contained"
        >
          <p style={{ marginBottom: 0, marginTop: 0, marginRight: 10 }}>Add</p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>Listing</p>
        </Button>
      </div>
      {/* Save Listings order button. */}
      <div style={{ marginLeft: 5 }}>
        <Button
          color="primary"
          onClick={() => handleActionSelect('Save Listings')}
          variant="contained"
        >
          Save
        </Button>
      </div>

      {/* Show add category modal. */}
      {showModal && (
        <AddCategoryForm
          close={closeModals}
          error={showError}
          success={showSuccess}
        />
      )}
      {snackbar && (
        <GenericSnackbar
          close={closeModals}
          message={message}
          open={snackbar}
        />
      )}
    </div>
  );
};

ListingTableHeader.propTypes = {
  categories: PropTypes.array,
  changeCategory: PropTypes.func,
  listings: PropTypes.array,
  reloadCategories: PropTypes.func,
  saveListings: PropTypes.func,
  sendToAddModal: PropTypes.func,
  setSearch: PropTypes.func,
  showFeatured: PropTypes.func,
  showSnackbar: PropTypes.func,
  toggleFeatured: PropTypes.func,
};
