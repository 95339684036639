import { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import {
  getCategoryCount,
  getCouponCount,
  getListingCount,
  getPostCount,
  getPromoCount,
  getPunchcardCount,
  getUserCount,
} from "../../Helpers/Analytics";
import { CircularProgress, Fade } from "@material-ui/core";

export const AnalyticsModule = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  /* When component mounts, get analytics data. */
  useEffect(() => {
    /* Grab all the data we possibly can. */
    const getData = async () => {
      const catCount = await getCategoryCount();
      const coupCount = await getCouponCount();
      const listCount = await getListingCount();
      const postCount = await getPostCount();
      const promoCount = await getPromoCount();
      const punchCount = await getPunchcardCount();
      const userCount = await getUserCount();

      const data = [
        { number: 1, Total: catCount, category: "Categories" },
        { number: 2, Total: coupCount, category: "Coupons" },
        { number: 3, Total: listCount, category: "Listings" },
        { number: 4, Total: postCount, category: "Company Posts" },
        { number: 5, Total: promoCount, category: "Promos" },
        { number: 6, Total: punchCount, category: "Punchcards" },
        { number: 7, Total: userCount, category: "Users" },
      ];

      setData(data);
      setLoading(false);
    };

    getData();
  }, []);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        marginBottom: 30,
        marginTop: -220,
        width: "100%",
      }}
    >
      {/* While we wait for our data... */}
      {loading && (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: 453,
            justifyContent: "center",
          }}
        >
          <CircularProgress size={100} />
        </div>
      )}

      {!loading && (
        <Fade in={true}>
          <div
            style={{
              backgroundColor: "#FFF",
              borderRadius: 20,
              height: 400,
              maxWidth: 700,
              minHeight: 400,
              padding: 20,
              width: "100%",
            }}
          >
            <ResponsiveBar
              data={data}
              keys={["Total"]}
              indexBy="category"
              margin={{ top: 50, right: 100, bottom: 50, left: 100 }}
              padding={0.3}
              layout="horizontal"
              colors={{ scheme: "category10" }}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};
