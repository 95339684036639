/* If no promos are shown, this component is displayed. */
export const NoPromosText = () => (
  <div style={style.main}>
    There are currently no promos, create some whenever you like by pressing the
    "Add New Promo" button next to the search bar.
  </div>
);

const style = {
  main: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginTop: 30,
    textAlign: 'center',
    width: '100%',
  },
};
