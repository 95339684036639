import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { rewardsRef } from '../../Api/FirebaseConfigHandler';
import { Header, SearchBar, SideBar } from '../../Components/Misc';
import { HowToRewards, RewardsTable } from '../../Components/Rewards';
import { getFormattedProjectName } from '../../Helpers/Utils';

// Global variables
let observer;

export const AllRewards = ({ history }) => {
  const [filteredItems, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);
  const projectId = localStorage.getItem('appId');
  const helmetTitle = getFormattedProjectName();

  /* Data request handler for this component. Calls firestore for all rewards.
     Should probably show these in order of most recent to the top with only 20 at a time. */
  const getData = useCallback(() => {
    let temparr = [];
    observer = rewardsRef.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added' && !change.doc.data().hidden) {
          const Obj = change.doc.data();
          Obj.createdAt = Obj.createdAt ? Obj.createdAt._seconds : 'Just Now';

          temparr.push(Obj);
        }

        temparr = temparr.sort((a, b) => {
          return b.createdAt - a.createdAt;
        });

        setLoading(false);
        setRewards(temparr);
      });
    });
  }, []);

  /* Get company rewards when the screen initially renders */
  useEffect(() => {
    getData();

    return () => {
      /* Stop listening for changes on our snapshot listener. */
      if (typeof observer === 'function') {
        observer();
      }
    };
  }, [getData]);

  /* Given an array from our search bar, set the filterUsers array in state. */
  const setSearch = (items) => {
    setFiltered(items);
  };

  /* Navigate to the "Add-reward" screen. */
  const handleNavigation = () => {
    history.push({ pathname: `/add-reward/${projectId}` });
  };

  return (
    <>
      <Helmet title={`${helmetTitle} | Rewards`} />
      <div
        style={{
          backgroundColor: '#e8e8e8',
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Rewards" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Header
            history={history}
            projectId={projectId}
            screen="Manage My Rewards"
          />

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <HowToRewards />

            <div
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: 1000,
                width: '85%',
              }}
            >
              {/* Search bar to display all users of our system. */}
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <SearchBar
                  list={rewards}
                  listType="Rewards"
                  returnSearch={setSearch}
                />
                <Button
                  color="primary"
                  onClick={handleNavigation}
                  style={{ marginLeft: 20 }}
                  variant="contained"
                >
                  Add New Reward
                </Button>
              </div>

              {!loading && rewards?.length < 1 && (
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  There are currently no rewards, create some whenever you like
                  by pressing the "Add New Reward" button next to the search
                  bar.
                </div>
              )}

              {rewards?.length > 0 && (
                <RewardsTable
                  history={history}
                  projectId={projectId}
                  rewards={filteredItems.length > 0 ? filteredItems : rewards}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
