import { Backdrop, Fade, Modal, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { auth } from '../../Api/FirebaseConfigHandler';
import { styles } from '../../Styles/Auth/ForgotPassword.js';
import { CustomButton, CustomTextField } from '../Custom/CustomForm';

export const ForgotPassword = ({ close, error, success }) => {
  const [email, setEmail] = useState('');

  const handleTextChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
    await auth
      .sendPasswordResetEmail(email)
      .then(() => {
        success();
      })
      .catch((err) => {
        error(err);
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={styles.modal}
      open={true}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Paper elevation={3} style={styles.paper}>
          <h3 style={styles.subheader}>Please enter your email.</h3>
          <hr style={styles.progressbar} />
          <form onSubmit={handleSubmission} style={styles.form}>
            <CustomTextField
              variant="outlined"
              type="email"
              style={styles.textfield}
              value={email}
              placeholder="Email"
              onChange={handleTextChange}
            />
            <div style={styles.buttoncontainer}>
              <CustomButton
                variant="contained"
                color="default"
                style={styles.leftbutton}
                onClick={close}
              >
                Close
              </CustomButton>
              <CustomButton
                variant="contained"
                color="default"
                style={styles.rightbutton}
                type="submit"
              >
                SUBMIT
              </CustomButton>
            </div>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};
