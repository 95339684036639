import { Box, Button, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { auth, logo } from '../../Api/FirebaseConfigHandler';
import { signOut } from '../../Helpers/Auth';
import { getUser } from '../../Helpers/Data';
import { styles } from '../../Styles/Auth/LoginModal';
import { CustomButton, CustomTextField } from '../Custom/CustomForm';
import { GenericSnackbar } from '../Misc';
import { ForgotPassword } from './ForgotPassword';

export const LoginModal = ({ history }) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [snackbar, setSnackbar] = useState(false);

  /* Close all modals (excluding the loginmodal). */
  const closeModals = () => {
    setForgotPassword(false);
    setSnackbar(false);
  };

  /* Handler for showing the forgot password modal. */
  const showForgotPassword = () => {
    setForgotPassword(true);
    setSnackbar(false);
  };

  /* Show error snackbar when something goes wrong with the forgot password process.*/
  const showError = (error) => {
    setForgotPassword(false);
    setMessage(error);
    setSnackbar(true);
  };

  /* Show success snackbar when forgot password email has been successfully sent. */
  const showSuccess = () => {
    const msg = `Success! You should get an email shortly from "Firebase". Don't forget to check your spam folder!`;
    setForgotPassword(false);
    setMessage(msg);
    setSnackbar(true);
  };

  return (
    <div style={styles.main}>
      <div style={styles.secondary}>
        <Paper elevation={3} style={styles.paper}>
          <Formik
            initialValues={{
              email: '',
              password: '',
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Invalid Email')
                .required('Please enter an email.'),
              password: Yup.string().required('Please enter a password.'),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setSubmitting(true);

                // Format our email nicely.
                let email = values.email.toLowerCase();
                email = email.trim();

                // Sign in the user.
                const { user } = await auth.signInWithEmailAndPassword(
                  email,
                  values.password
                );

                // Lookup the user's firestore info and verify they are an employee.
                const data = await getUser(user.uid);
                const role = data.role;
                const projectId = localStorage.getItem('appId');

                if (
                  role === 'Admin' ||
                  role === 'Manager' ||
                  role === 'Employee'
                ) {
                  history.push({ pathname: `/dashboard/${projectId}` });
                } else {
                  await signOut();
                  // eslint-disable-next-line no-new-wrappers
                  throw new String("Only employee's may visit this site.");
                }

                setStatus({ success: true });
                setSubmitting(false);
              } catch (e) {
                console.error('CAUGHT ERROR:', e);
                let errmessage = e;

                if (e.code) {
                  switch (e.code) {
                    case 'auth/user-not-found':
                      errmessage = 'No user with that email exists.';
                      break;
                    case 'auth/invalid-email':
                      errmessage = 'Please enter a valid email.';
                      break;
                    case 'auth/wrong-password':
                      errmessage = 'Your email or password is incorrect.';
                      break;
                    default:
                      errmessage = 'Something went wrong, please try again.';
                  }
                }

                setStatus({ success: false });
                setErrors({ submit: errmessage });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.imgcontainer}>
                  <img src={logo} alt="logo.png" width="100%" />
                </div>
                <label style={styles.textWrapper}>
                  Login to your admin portal
                </label>
                <CustomTextField
                  error={Boolean(touched.email && errors.email)}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Email"
                  style={styles.textfield}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <CustomTextField
                  error={Boolean(touched.password && errors.password)}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Password"
                  style={styles.textfield}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <CustomButton
                  color="default"
                  disabled={isSubmitting}
                  fullWidth
                  style={styles.loginbutton}
                  type="submit"
                  variant="contained"
                >
                  Login
                </CustomButton>
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={showForgotPassword}
                  style={styles.forgotpasswordbutton}
                >
                  Forgot Password?
                </Button>
                <Box style={styles.errorBox}>
                  {errors.submit && (
                    <Alert severity="error">
                      <div>{errors.submit}</div>
                    </Alert>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
      {forgotPassword && (
        <ForgotPassword
          close={closeModals}
          error={showError}
          success={showSuccess}
        />
      )}
      {snackbar && (
        <GenericSnackbar
          close={closeModals}
          message={message}
          open={snackbar}
        />
      )}
    </div>
  );
};

LoginModal.propTypes = {
  history: PropTypes.func,
};
