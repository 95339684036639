import {
  categoriesRef,
  couponsRef,
  listingsRef,
  postsRef,
  promosRef,
  punchRef,
  userRef,
} from "../../Api/FirebaseConfigHandler";

/* Get the number of categories for our listings. */
export async function getCategoryCount() {
  return await categoriesRef.get().then((snapshot) => {
    return snapshot.size;
  });
}

/* Get the number of coupons. */
export async function getCouponCount() {
  return await couponsRef.get().then((snapshot) => {
    return snapshot.size;
  });
}

/* Get the number of listings. */
export async function getListingCount() {
  return await listingsRef.get().then((snapshot) => {
    return snapshot.size;
  });
}

/* Get the number of posts. */
export async function getPostCount() {
  return await postsRef.get().then((snapshot) => {
    return snapshot.size;
  });
}

/* Get the number of promos. */
export async function getPromoCount() {
  return await promosRef.get().then((snapshot) => {
    return snapshot.size;
  });
}

/* Get the number of punchcards. */
export async function getPunchcardCount() {
  return await punchRef.get().then((snapshot) => {
    return snapshot.size;
  });
}

/* Get the number of users. */
export async function getUserCount() {
  return await userRef.get().then((snapshot) => {
    return snapshot.size;
  });
}
