export const styles = {
  main: {
    backgroundColor: '#e8e8e8',
    width: '100%',
  },
  secondaryContainer: {
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 10,
  },
  imageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  imageWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '50%',
    justifyContent: 'center',
    maxWidth: '20%',
    minWidth: '15%',
  },
  image: {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    height: '50%',
    width: '90%',
  },
  textContainer: { padding: 20, width: '50%' },
  divider: {
    backgroundColor: '#424242',
    borderColor: '#000',
    height: 5,
    borderRadius: 30,
    marginTop: 40,
    width: '95%',
  },
};
