import Slide from "@material-ui/core/Slide";
import React from "react";
import { Helmet } from "react-helmet";
// Import components
import { LoginModal } from "../../Components/Auth";
import { getFormattedProjectName } from "../../Helpers/Utils";

/* Show a slide animation depending on which screen the user is coming from. */
export const Login = ({ history, location }) => {
  let direction = "left";
  if (location.state) {
    direction = location.state.direction ? location.state.direction : "left";
  }

  const helmetTitle = getFormattedProjectName();

  return (
    <>
      <Helmet title={`${helmetTitle} | Dashboard`} />
      <Slide direction={direction} in={true} mountOnEnter unmountOnExit>
        <div>
          <LoginModal history={history} />
        </div>
      </Slide>
    </>
  );
};
