import React, { useEffect, useState } from "react";
import {
  editListing,
  getAllCategories,
  removeListing,
} from "../../Helpers/Data";
import { Button } from "@material-ui/core";

// Import Components
import {
  Header,
  GenericAddButton,
  GenericSnackbar,
  SideBar,
} from "../../Components/Misc";
import { EditListingForm } from "../../Components/Listings";
import { ConfirmDeleteModal } from "../../Components/Modals";

export const EditListing = ({ history, location }) => {
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const projectId = localStorage.getItem("appId");

  /* Get all available categories upon first render. */
  useEffect(() => {
    /* Get all categories from Firestore. */
    const callGetCategories = async () => {
      const data = await getAllCategories();
      setCategories(data);
    };

    callGetCategories();
  }, []);

  /* Show some loading UI while we edit the document in firebase storage. */
  const showLoadingProcess = (state) => {
    setSubmitting(true);
    callEditListing(state);
  };

  /* Find the document in Firestore and edit the appropriate fields. */
  const callEditListing = async (state) => {
    // Parse through what's changed and what hasn't changed and save to the listing object.
    let listing = location.state;
    const stateListing = state;

    listing.category = stateListing.category
      ? stateListing.category
      : listing.category;
    listing.description = stateListing.description
      ? stateListing.description
      : listing.description;
    listing.featured = stateListing.featured
      ? stateListing.featured
      : listing.featured;
    listing.hidden = stateListing.hidden ? stateListing.hidden : listing.hidden;
    listing.imageName = stateListing.imageName
      ? stateListing.imageName
      : listing.imageName;
    listing.name = stateListing.name ? stateListing.name : listing.name;
    listing.price = stateListing.price ? stateListing.price : listing.price;
    listing.url = stateListing.url ? stateListing.url : listing.url;

    const edited = await editListing(listing);
    const msg =
      edited === "SUCCESS" ? "Your listing has been updated!" : edited;
    const shouldShow = edited === "SUCCESS";
    setSubmitting(false);
    showSnackbar(msg, shouldShow);
  };

  /* Close all modals. */
  const closeModals = () => {
    setShowDeleteModal(false);
    setSnackbar(false);
  };

  /* Initiate the redirect timer, used for when operation is successful and user needs to go back. */
  const initRedirectTimer = () => {
    setTimeout(() => {
      navigateBack();
    }, 3000);
  };

  /* Send the user to the previous screen. */
  const navigateBack = () => {
    history.push({ pathname: `/listings/${projectId}` });
  };

  /* Call our removeListing function in Data.js */
  const callRemoveListing = async () => {
    const listingId = location.state._id;
    const removed = await removeListing(listingId);
    const msg =
      removed === "SUCCESS"
        ? "Your listing has been successfully deleted."
        : removed;
    const shouldShow = removed === "SUCCESS";
    setShowDeleteModal(false);
    showSnackbar(msg, shouldShow);
  };

  /* Show modal that asks the user to confirm deleting a listing. */
  const showConfirmDeleteModal = () => {
    setShowDeleteModal(true);
  };

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = (msg, redirect) => {
    setMessage(msg);
    setSnackbar(true);
    if (redirect) {
      initRedirectTimer();
    }
  };

  const listing = location.state;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <SideBar history={history} projectId={projectId} screen="Listings" />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header
          history={history}
          projectId={projectId}
          screen="Manage My Listings"
        />

        <div
          style={{
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            maxWidth: 600,
            width: "100%",
            marginTop: 100,
          }}
        >
          <div style={{ marginBottom: 30, paddingLeft: 120, width: "100%" }}>
            <Button
              color="primary"
              onClick={history.goBack}
              variant="contained"
            >
              Back
            </Button>
          </div>
          {categories && categories.length > 0 && (
            <EditListingForm
              categories={categories}
              confirmDelete={showConfirmDeleteModal}
              error={showSnackbar}
              listing={listing}
              submit={showLoadingProcess}
              submitting={submitting}
            />
          )}

          {/* Show a back button in the top left corner of the page. */}
          <div style={{ position: "fixed", left: 100, top: 80 }}>
            <GenericAddButton
              action={navigateBack}
              screen="listings"
              text="BACK"
            />
          </div>
          {/* Show modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
          {showDeleteModal && (
            <ConfirmDeleteModal
              category="listing"
              close={closeModals}
              submit={callRemoveListing}
            />
          )}
        </div>
      </div>
    </div>
  );
};
