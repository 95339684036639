import { Button, InputLabel } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { auth } from '../../Api/FirebaseConfigHandler';
// Import components
import { CustomTextField } from '../../Components/Custom/CustomForm';
import {
  GenericModal,
  GenericSnackbar,
  GenericSpinner,
  Header,
  SideBar,
} from '../../Components/Misc';
// Getters n Setters
import { getFirebaseUrl } from '../../Config';
import { signOutRedirect } from '../../Helpers/Auth';
import { getUser, updateUserPIN } from '../../Helpers/Data';
import en from '../../Helpers/Text/en.json';
import { getFormattedProjectName } from '../../Helpers/Utils';
import useAuth from '../../Hooks/useAuth';
import { styles } from '../../Styles/Admin/AdminProfile';

/* This screen only serves as the authenticated user's profile screen. Only they can access it and change their info. */
export const AdminProfile = (props) => {
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [helpModal, setHelpModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [mode, setMode] = useState('view');
  const [name, setName] = useState('');
  const [PIN, setPIN] = useState('');
  const [snackbar, setSnackbar] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userObj, setUserObj] = useState(user);

  const projectId = getFirebaseUrl();
  const helmetTitle = getFormattedProjectName();

  // Auth check
  if (!user?.email) {
    const projectId = localStorage.getItem('appId');
    props.history.push({ pathname: `/login/${projectId}` });
  }

  /* Get the current signed in user's data. */
  useEffect(() => {
    const listener = auth.onAuthStateChanged((user) => {
      if (user) getUserData(user);
    });

    // After 2 seconds, discontinue the ener.
    setTimeout(() => {
      listener();
    }, 2000);

    return () => {
      if (typeof listener === 'function') listener();
    };
  }, []);

  /* Handler for making Data.js call to sign the user out. */
  const callSignOut = async () => {
    await signOutRedirect(props);
  };

  /* Check to see which mode we are in. 
     If edit, return true show the submit button at the bottom of the screen.
     If view, the edit button is not disabled at the bottom of the screen. */
  const checkMode = (mode) => {
    return mode === 'edit' ? false : true;
  };

  /* Close all modals on screen. */
  const closeModals = () => {
    setHelpModal(false);
    setSnackbar(false);
  };

  /* On an auth state change, update the user for this screen. */
  const getUserData = async ({ uid }) => {
    const msg =
      "We're having some trouble getting your info. Please try again later.";

    const currentUser = await getUser(uid);

    if (!!currentUser) {
      setLoading(false);
      setEmail(currentUser.email);
      setName(currentUser.name);
      setPIN(currentUser.PIN);
      setUserObj(currentUser);
    } else {
      setLoading(false);
      setMessage(msg);
      setSnackbar(true);
    }
  };

  /* Helper function to display the help modal on this screen. */
  const showHelpModal = () => {
    setHelpModal(false);
  };

  /* Disable the submit button so user's cannot splurge on requets. */
  const triggerSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
  };

  useEffect(() => {
    if (submitting) {
      updateAccount();
    }
  }, [submitting]);

  /* For now, we only allow staff to edit their PIN's  */
  const updateAccount = async ({ PIN, user }) => {
    if (PIN !== user.PIN) {
      const updated = await updateUserPIN(user._id, PIN);

      const msg = 'Your account has been updated.';

      setMode('view');
      setMessage(updated === 'SUCCESS' ? msg : updated);
      setSnackbar(true);
      setSubmitting(false);
    } else {
      const msg = 'There is nothing to save.';
      setMode('view');
      setMessage(msg);
      setSnackbar(true);
      setSubmitting(false);
    }
  };

  /* Validate the user is only inputting numbers for their pin. */
  const validatePIN = (e) => {
    setPIN(e.target.value);
  };

  return (
    <>
      <Helmet title={`${helmetTitle} | ${name}'s Profile`} />
      <div style={styles.screencontainer}>
        <SideBar history={props.history} projectId={projectId} />
        <div style={styles.headercontainer}>
          <Header
            history={props.history}
            projectId={projectId}
            screen="My Account"
          />
          <div style={styles.maincontainer}>
            {/* Show spinner for when a user is being deleted. */}
            {loading && (
              <div style={styles.progresscontainer}>
                <div style={styles.progressseccontainer}>
                  <GenericSpinner show={loading} />
                </div>
              </div>
            )}

            {userObj && (
              <div style={styles.contentcontainer}>
                <div style={styles.backbuttoncontainer}>
                  <Button
                    color="primary"
                    onClick={props.history.goBack}
                    style={styles.boldfont}
                    variant="outlined"
                  >
                    BACK
                  </Button>
                </div>
                <form onSubmit={triggerSubmit} style={styles.form}>
                  <div style={styles.accountcontentmetaheader}>
                    <h1 style={styles.formheader}>{userObj.name}</h1>
                    <div style={styles.metaheaderbuttoncontainer}>
                      <Button
                        color="primary"
                        onClick={showHelpModal}
                        style={styles.boldfont}
                        variant="contained"
                      >
                        HELP
                      </Button>
                      <Button
                        color="secondary"
                        onClick={callSignOut}
                        style={styles.spaceleft}
                        variant="contained"
                      >
                        Sign Out
                      </Button>
                    </div>
                  </div>
                  <h3 style={styles.formsubheader}>{userObj.role}</h3>
                  <hr style={styles.formbreakline} />

                  <InputLabel style={styles.label}>Display Name</InputLabel>
                  <CustomTextField
                    id="outlined-basic"
                    style={styles.usertextinput}
                    variant="outlined"
                    disabled
                    type="text"
                    value={name}
                    placeholder={userObj.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {user && (
                    <>
                      <InputLabel style={styles.label}>PIN</InputLabel>
                      <CustomTextField
                        id="outlined-basic"
                        style={styles.pininput}
                        variant="outlined"
                        inputProps={{
                          maxLength: 4,
                          minLength: 4,
                        }}
                        disabled={checkMode(mode)}
                        value={PIN}
                        placeholder={userObj.PIN}
                        onChange={validatePIN}
                      />

                      <InputLabel style={styles.label}>Email</InputLabel>
                      <CustomTextField
                        id="outlined-basic"
                        style={styles.usertextinput}
                        variant="outlined"
                        type="text"
                        disabled
                        value={email}
                        placeholder={userObj.email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </>
                  )}

                  <hr style={styles.userrightbutton} />

                  {mode === 'view' && (
                    <Button
                      color="primary"
                      onClick={() => setMode('edit')}
                      style={styles.boldfont}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  )}
                  {mode === 'edit' && (
                    <Button
                      color="primary"
                      disabled={submitting}
                      variant="contained"
                      style={styles.boldfont}
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </div>
            )}
          </div>

          {/* Show event modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
          {helpModal && (
            <GenericModal close={closeModals} text={en.help.myprofile} />
          )}
        </div>
      </div>
    </>
  );
};
