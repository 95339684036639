import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  LinearProgress,
  MenuItem,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { storageRef } from '../../Api/FirebaseConfigHandler';
// Import components
import {
  CustomButton,
  CustomSelect,
  CustomTextField,
} from '../../Components/Custom/CustomForm';
import { ImageModal } from '../Misc';

export const AddListingForm = ({ categories, error, submit, submitting }) => {
  const [imageName, setImageName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');

  /* Show some loading animation while the image is processing. */
  const handlePictureUpload = (files) => {
    setLoading(true);
    uploadPicture(files);
  };

  /* Upload the image blob to firebase storage then save the download url to our listing object. */
  const uploadPicture = async (files) => {
    try {
      // We only want 1 image to be uploaded
      let image = files[0];

      const ref = storageRef.child(`/Listings/${image.name}`);

      const downloadUrl = await ref.put(image).then(async () => {
        return await ref.getDownloadURL();
      });

      setImageName(image.name);
      setLoading(false);
      setUrl(downloadUrl);
    } catch (err) {
      setLoading(false);
      error(err);
    }
  };

  /* Show modal to blow up image with onclick. */
  const enhanceImage = () => {
    setShowModal(true);
  };

  /* Close all modals */
  const closeModals = () => {
    setShowModal(false);
  };

  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        padding: 40,
      }}
    >
      <h3 style={{ textAlign: 'center' }}>Create A New Listing</h3>
      <Formik
        initialValues={{
          category: categories[0],
          description: '',
          featured: false,
          name: '',
          price: 0.0,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string()
            .min(10)
            .required('Please provide a description for this listing.'),
          featured: Yup.boolean().required(),
          name: Yup.string().required(
            'Please provide a name for this listing.'
          ),
          price: Yup.number()
            .min(0.01)
            .required('Please provide a price for this listing.'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // Do some last minute validation here.
            if (!imageName || !url || imageName.length < 1 || url.length < 1) {
              // eslint-disable-next-line no-new-wrappers
              throw new String('Please upload an image for this listing.');
            }

            let st = values;
            st.imageName = imageName;
            st.url = url;
            submit(st);

            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error('CAUGHT ERROR:', err);

            setStatus({ success: false });
            setErrors({ submit: err });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <CustomTextField
              error={Boolean(touched.name && errors.name)}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Name"
              style={{ marginBottom: 20, width: '100%' }}
              value={values.name}
              variant="outlined"
            />
            <CustomTextField
              error={Boolean(touched.description && errors.description)}
              multiline
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Description"
              rows={8}
              style={{ marginBottom: 20, width: '100%' }}
              value={values.description}
              variant="outlined"
            />
            <FormControl
              style={{ marginBottom: 20, width: '100%' }}
              variant="outlined"
            >
              <CustomSelect
                name="category"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
              >
                {categories.map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
            {url && url.length > 0 && (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 10,
                }}
              >
                <img
                  alt="listing-pic"
                  src={url}
                  onClick={enhanceImage}
                  width="100%"
                  style={{
                    borderRadius: 10,
                    cursor: 'pointer',
                    height: '100%',
                    maxWidth: 400,
                  }}
                />
              </div>
            )}

            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 20,
                marginTop: 20,
                width: '100%',
              }}
            >
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{
                  backgroundColor: '#424242',
                  display: 'none',
                  width: '100%',
                }}
                onChange={(e) => handlePictureUpload(e.target.files)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  color="primary"
                  component="span"
                  style={{ fontWeight: 'bold', width: '100%' }}
                  variant="contained"
                >
                  Upload Image
                </Button>
              </label>
              {loading && (
                <CircularProgress color="primary" style={{ marginLeft: 20 }} />
              )}
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Checkbox
                checked={values.featured}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                name="featured"
                onChange={handleChange}
              />
              <p>Is this listing featured?</p>
            </div>

            <CustomTextField
              error={Boolean(touched.price && errors.price)}
              name="price"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Price (Ex: 1.99)"
              type="tel"
              value={values.price}
              variant="outlined"
            />
            <hr style={{ width: '100%' }} />
            {submitting && (
              <LinearProgress
                color="primary"
                style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
              />
            )}
            <CustomButton
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              type="submit"
            >
              SUBMIT
            </CustomButton>
            <Box style={{ marginTop: 10 }}>
              {errors.submit && (
                <Alert severity="error">
                  <div>{errors.submit}</div>
                </Alert>
              )}
            </Box>
          </form>
        )}
      </Formik>

      {showModal && (
        <ImageModal close={closeModals} imgUrl={url} open={showModal} />
      )}
    </Paper>
  );
};

AddListingForm.propTypes = {
  error: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};
