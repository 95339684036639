import { Backdrop, Fade, Modal } from '@material-ui/core';
import React from 'react';
import { styles } from '../../Styles/Misc/GenericModal';

export const ImageModal = ({ close, imgUrl, open }) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    style={styles.modal}
    open={open}
    onClose={close}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={true}>
      <img alt="Modal-img" src={imgUrl} />
    </Fade>
  </Modal>
);
