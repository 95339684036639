import Fade from '@material-ui/core/Fade';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  GenericSnackbar,
  Header,
  SearchBar,
  SideBar,
} from '../../Components/Misc';
import { UsersTable } from '../../Components/Users';
import { getAllUsers } from '../../Helpers/Data';
import { getFormattedProjectName } from '../../Helpers/Utils';

export const AllUsers = ({ history }) => {
  const projectId = localStorage.getItem('appId');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [users, setUsers] = useState([]);
  const helmetTitle = getFormattedProjectName();

  useEffect(() => {
    /* Used to call our getAllUsers function as fast as we can. */
    const callGetUsers = async () => {
      const data = await getAllUsers();
      setUsers(data);
      setLoading(false);
    };

    callGetUsers();
  }, []);

  /* Close all modals. */
  const closeModals = () => {
    setSnackbar(false);
  };

  /* Given an array from our search bar, set the filterUsers array in state. */
  const setSearch = (filteredData) => {
    setFilteredUsers(filteredData);
  };

  return (
    <>
      <Helmet title={`${helmetTitle} | Users`} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Users" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Header
            history={history}
            projectId={projectId}
            screen="Manage My Users"
          />

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
              marginTop: 100,
            }}
          >
            {!loading && users && users.length < 1 && (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                There are currently no users, please reach out to some people
                and have them sign up on your app!
              </div>
            )}

            {/* Search bar to display all users of our system. */}
            <SearchBar list={users} listType="Users" returnSearch={setSearch} />

            {users && users.length > 0 && (
              <Fade in={users.length > 0} timeout={{ enter: 2000 }}>
                <UsersTable
                  history={history}
                  projectId={projectId}
                  users={filteredUsers.length > 0 ? filteredUsers : users}
                />
              </Fade>
            )}

            {/* Show conditionally rendered comps here */}
            {snackbar && (
              <GenericSnackbar close={closeModals} message="" open={snackbar} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
