import { useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { addTeammember } from "../../Helpers/Data";

// Import components
import {
  Header,
  GenericAddButton,
  GenericSnackbar,
  SideBar,
} from "../../Components/Misc";
import { AddTeammateForm } from "../../Components/Team";

/* Add a new teammate item. */
export const AddTeamMember = ({ history }) => {
  const [formState, setFormState] = useState(null);
  const [message, setMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const projectId = localStorage.getItem("appId");

  /* Send the user to the previous screen. */
  const navigateBack = useCallback(() => {
    history.push({ pathname: `/team/${projectId}` });
  }, [history, projectId]);

  /* Initiate the redirect timer, used for when operation is successful and user needs to go back. */
  const initRedirectTimer = useCallback(() => {
    setTimeout(() => {
      navigateBack();
    }, 3000);
  }, [navigateBack]);

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = useCallback(
    (msg, redirect) => {
      setMessage(msg);
      setSnackbar(true);
      if (redirect) {
        initRedirectTimer();
      }
    },
    [initRedirectTimer]
  );

  /* Save the teammate in firestore. */
  const callAddTeammember = useCallback(async () => {
    const saved = await addTeammember(formState);

    const shouldShow = saved === "SUCCESS";
    const msg = shouldShow ? "Your teammate has been added!" : saved;
    setSubmitting(false);
    showSnackbar(msg, shouldShow);
  }, [formState, showSnackbar]);

  /* Save the teammember in firebase storage. */
  const showLoadingProcess = (state) => {
    setSubmitting(true);
    setFormState(state);
  };

  useEffect(() => {
    if (submitting) callAddTeammember();
  }, [callAddTeammember, submitting]);

  /* Close all modals. */
  const closeModals = () => {
    setSnackbar(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <SideBar history={history} projectId={projectId} screen="Team" />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header
          history={history}
          projectId={projectId}
          screen="Create New Teammate"
        />

        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            width: "100%",
            marginTop: 100,
          }}
        >
          <div style={{ marginBottom: 30, paddingLeft: 30, width: "100%" }}>
            <Button
              color="primary"
              onClick={history.goBack}
              variant="contained"
            >
              Back
            </Button>
          </div>

          <AddTeammateForm
            error={showSnackbar}
            submit={showLoadingProcess}
            submitting={submitting}
          />

          {/* Show a back button in the top left corner of the page. */}
          <div style={{ position: "fixed", left: 100, top: 80 }}>
            <GenericAddButton action={navigateBack} screen="team" text="BACK" />
          </div>
          {/* Show modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
        </div>
      </div>
    </div>
  );
};
