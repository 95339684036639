import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { addPost } from "../../Helpers/Data";

// Import components
import {
  Header,
  GenericAddButton,
  GenericSnackbar,
  SideBar,
} from "../../Components/Misc";
import { AddPostForm } from "../../Components/Posts";

/* Add a new post to Firestore. By default, all user's who have notifications working will receive it. */
export const AddPost = ({ history }) => {
  const [message, setMessage] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const projectId = localStorage.getItem("appId");

  /* Save the post in firebase storage. */
  const showLoadingProcess = (state) => {
    setSubmitting(true);
    callAddPost(state);
  };

  /* Save the post in firebase storage. */
  const callAddPost = async ({ body, title }) => {
    const saved = await addPost(body, title);
    const msg =
      saved === "SUCCESS"
        ? "Your post has been saved! Users will receive it as soon as possible."
        : saved;
    const shouldShow = saved === "SUCCESS";
    setSubmitting(false);
    showSnackbar(msg, shouldShow);
  };

  /* Close all modals. */
  const closeModals = () => {
    setSnackbar(false);
  };

  /* Initiate the redirect timer, used for when operation is successful and user needs to go back. */
  const initRedirectTimer = () => {
    setTimeout(() => {
      navigateBack();
    }, 3000);
  };

  /* Send the user to the previous screen. */
  const navigateBack = () => {
    history.push({ pathname: `/posts/${projectId}` });
  };

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = (msg, redirect) => {
    setMessage(msg);
    setSnackbar(true);
    if (redirect) {
      initRedirectTimer();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <SideBar history={history} projectId={projectId} screen="Posts" />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header
          history={history}
          projectId={projectId}
          screen="Manage My Posts"
        />

        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            width: "100%",
            marginTop: 100,
          }}
        >
          <div style={{ marginBottom: 30, paddingLeft: 30, width: "100%" }}>
            <Button
              color="primary"
              onClick={history.goBack}
              variant="contained"
            >
              Back
            </Button>
          </div>

          <AddPostForm
            error={showSnackbar}
            submit={showLoadingProcess}
            submitting={submitting}
          />

          {/* Show a back button in the top left corner of the page. */}
          <div style={{ position: "fixed", left: 100, top: 80 }}>
            <GenericAddButton
              action={navigateBack}
              screen="posts"
              text="BACK"
            />
          </div>
          {/* Show modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
        </div>
      </div>
    </div>
  );
};
