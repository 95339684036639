const dynamicWidth = window.innerWidth;

export const FacebookPosts = () => {
  // Resize the height of the facebook component to our desired height. Then
  // Rerender the iframe
  if (
    window.location.pathname.includes('facebook') &&
    window.FB &&
    window.FB.CustomerChat
  ) {
    window.FB.CustomerChat.hide();
    window.FB.XFBML.parse();
  }

  let facebookInfo = 777235222402643;

  let siteCode = window.location.search;

  if (siteCode.length === 0) {
    siteCode = window.location.pathname.split('$')[1];
    siteCode = '?' + siteCode;
  }

  siteCode = siteCode === '?undefined' ? '?HGrille' : siteCode;

  switch (siteCode) {
    case '?HGrille':
      facebookInfo = 247706520233;
      break;
    case '?Authoritek-Testing':
      facebookInfo = 777235222402643;
      break;
    case '?317-Coffee':
      facebookInfo = 297456927514336;
      break;
    case '?BillingPortal':
      facebookInfo = 777235222402643;
      break;
    case '?Saugatuck-Schools':
      facebookInfo = 777235222402643;
      break;
    case '?Frankenmuth-Schools':
      facebookInfo = 777235222402643;
      break;
    case '?Flamingo-Consulting':
      facebookInfo = 107499477532770;
      break;
    case '?Man-Cave':
      facebookInfo = 116508275537452;
      break;
    case '?Random':
      facebookInfo = 112889354503812;
      break;
    case '?Townline-Hatchery':
      facebookInfo = 453952121376766;
      break;
    default:
      facebookInfo = 777235222402643;
      break;
  }

  return (
    <div
      class="fb-page"
      data-href={`https://www.facebook.com/${facebookInfo}`}
      data-tabs="timeline"
      data-width={dynamicWidth}
      data-height="700"
      data-small-header="true"
      data-adapt-container-width="true"
      data-hide-cover="true"
      data-show-facepile="true"
    >
      <blockquote
        cite={`https://www.facebook.com/${facebookInfo}`}
        class="fb-xfbml-parse-ignore"
      >
        <a href={`https://www.facebook.com/${facebookInfo}`}>Authoritek</a>
      </blockquote>
    </div>
  );
};
