import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { postsRef } from '../../Api/FirebaseConfigHandler';
import { Header, SearchBar, SideBar } from '../../Components/Misc';
import { HowToPosts, PostsTable } from '../../Components/Posts';
import { getFormattedProjectName } from '../../Helpers/Utils';

// Global variables
let observer;

export const AllPosts = ({ history }) => {
  const [filteredItems, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const projectId = localStorage.getItem('appId');
  const helmetTitle = getFormattedProjectName();

  /* Get company posts when the screen initially renders */
  useEffect(() => {
    /* Data request handler for this component. Calls firestore for all company posts.
     Should probably show these in order of most recent to the top with only 20 at a time. */
    const getData = () => {
      let temparr = [];
      observer = postsRef.onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added' && !change.doc.data().hidden) {
            const Obj = change.doc.data();
            Obj.createdAt = Obj.createdAt ? Obj.createdAt._seconds : 'Just Now';

            temparr.push(Obj);
          }

          temparr = temparr.sort((a, b) => {
            return b.createdAt - a.createdAt;
          });

          setLoading(false);
          setPosts(temparr);
        });
      });
    };

    getData();

    return () => {
      /* Stop listening for changes on our snapshot listener. */
      if (typeof observer === 'function') {
        observer();
      }
    };
  }, []);

  /* Given an array from our search bar, set the filterUsers array in state. */
  const setSearch = (items) => {
    setFiltered(items);
  };

  /* Navigate to the "Add-post" screen. */
  const handleNavigation = () => {
    history.push({ pathname: `/add-post/${projectId}` });
  };

  return (
    <>
      <Helmet title={`${helmetTitle} | Posts`} />
      <div
        style={{
          backgroundColor: '#e8e8e8',
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <SideBar history={history} projectId={projectId} screen="Posts" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <Header
            history={history}
            projectId={projectId}
            screen="Manage My Posts"
          />

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <HowToPosts />

            <div
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: 1000,
                width: '85%',
              }}
            >
              {/* Search bar to display all users of our system. */}
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <SearchBar
                  list={posts}
                  listType="Posts"
                  returnSearch={setSearch}
                />
                <Button
                  color="primary"
                  onClick={handleNavigation}
                  style={{ marginLeft: 20 }}
                  variant="contained"
                >
                  Add New Post
                </Button>
              </div>

              {!loading && posts && posts.length < 1 && (
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  There are currently no posts, create some whenever you like by
                  pressing the "Add New Post" button next to the search bar.
                </div>
              )}

              {posts && posts.length > 0 && (
                <PostsTable
                  history={history}
                  projectId={projectId}
                  posts={filteredItems.length > 0 ? filteredItems : posts}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
