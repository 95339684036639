import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";

/* This component routes the staff member to the appropriate screen. */
export const ActionCard = ({
  action,
  backgroundColor,
  buttonTitle,
  description,
  image,
  textColor,
}) => {
  const [hovered, setHover] = useState(false);
  const classes = useStyles();
  return (
    <Card
      className={hovered ? classes.hoveredRoot : classes.root}
      onClick={action}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <CardMedia className={classes.header}>
        <img src={image} alt="header-illustration" className={classes.image} />
      </CardMedia>
      <CardContent style={{ backgroundColor: backgroundColor, opacity: 1 }}>
        <Typography
          style={{ color: textColor, fontSize: 18, fontWeight: "bold" }}
        >
          {buttonTitle}
        </Typography>
        <Typography variant="body2" component="p" style={{ color: textColor }}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  hoveredRoot: {
    cursor: "pointer",
    margin: 20,
    maxWidth: 350,
    minWidth: 350,
    borderColor: "#3366FF",
    borderRadius: 20,
    borderWidth: 1,
    opacity: 0.9,
  },
  root: {
    cursor: "pointer",
    margin: 20,
    maxWidth: 350,
    minWidth: 350,
    borderColor: "#3366FF",
    borderRadius: 20,
    borderWidth: 1,
  },
  actions: {
    justifyContent: "center",
  },
  buttontext: {
    fontWeight: "bold",
  },
  contentBackground: {},
  header: {
    alignItems: "center",
    backgroundColor: "#F4F6FA",
    fontWeight: "bold",
    opacity: 0.85,
    textAlign: "center",
  },
  image: {
    height: 150,
    marginBottom: -3,
    maxHeight: 200,
    maxWidth: 300,
    paddingTop: 10,
    opacity: 1,
  },
  title: {
    fontSize: 14,
  },
});
