/* This file holds the default remote config values for each project. */

// Default values for Hudsonville Grille.
export const HGrilleRC = {
  birthdays: true,
  listings: true,
  posts: false,
  promos: false,
  rewards: false,
  manual_teams: true,
  users: true,
};

// Default values for our testing instance.
export const TestRC = {
  birthdays: true,
  listings: true,
  posts: true,
  promos: true,
  rewards: true,
  manual_teams: true,
  users: true,
};

// Default values for 317-Coffee.
export const CoffeeRC = {
  android_link:
    'https://play.google.com/store/apps/details?id=org.authoritek.a317_Coffee',
  birthdays: true,
  ios_link: 'https://apps.apple.com/us/app/317-coffee/id1561834730',
  listings: true,
  posts: false,
  promos: false,
  rewards: true,
  manual_teams: true,
  users: true,
};

// Default values for Saugatuck Schools.
export const SaugatuckRC = {
  birthdays: false,
  listings: false,
  posts: false,
  promos: false,
  rewards: false,
  manual_teams: false,
  users: true,
};

// Default values for Frankenmuth Schools.
export const FrankenmuthRC = {
  birthdays: false,
  listings: false,
  posts: false,
  promos: false,
  rewards: false,
  manual_teams: false,
  users: true,
};

// Default values for Townline Hatchery
export const TownlineHatcheryRC = {
  birthdays: true,
  listings: true,
  posts: true,
  promos: true,
  rewards: false,
  manual_teams: true,
  users: true,
};

// Default values for Man-Cave
export const ManCaveRC = {
  android_link: '',
  birthdays: false,
  ios_link: '',
  listings: true,
  posts: false,
  promotions: false,
  rewards: false,
  manual_teams: true,
  users: true,
};

// Default values for Flamingo Consulting
export const FlamingoConsultingRC = {
  android_link: '',
  birthdays: false,
  ios_link: '',
  listings: false,
  posts: true,
  promotions: true,
  rewards: true,
  manual_teams: true,
  users: true,
};
