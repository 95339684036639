/* Used to generate a new id for a firestore document. */
export function generateId() {
  const uniqueId =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  return uniqueId;
}

/* Render an appropriate date given a Unix timestamp. */
export function renderDate(seconds) {
  if (typeof seconds === "string") {
    return seconds;
  }

  let now = new Date();
  now = now.getTime();
  seconds = seconds * 1000;

  // Calculate the diff in time for the inputted date and the current date.
  let diffMs = now - seconds;
  let diffmins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  let diffhours = Math.round(diffMs / (1000 * 3600));
  let diffdays = diffMs / (1000 * 3600 * 24);

  // If the date is past a day or is in the future.
  if (diffdays > 1 || diffdays < -1) {
    let n = new Date(seconds);
    let m = n.getMonth() + 1;
    let d = n.getDate();
    let y = n.getFullYear();
    return m + "-" + d + "-" + y;
  }
  // Date was today or yesterday
  if (diffmins < 2) {
    return "1 minute ago";
  } else if (diffhours < 1) {
    return diffmins + " mins ago";
  } else if (diffhours === 1) {
    return diffhours + " hour ago";
  } else if (diffhours > 1 && diffhours < 24) {
    return diffhours + " hours ago";
  } else {
    let n = new Date(seconds);
    let m = n.getMonth() + 1;
    let d = n.getDate();
    let y = n.getFullYear();
    return m + "-" + d + "-" + y;
  }
}
