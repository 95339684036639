import {
  Backdrop,
  Button,
  Fade,
  LinearProgress,
  Modal,
  Paper,
} from "@material-ui/core";

export const ConfirmDeleteModal = ({ category, close, loading, submit }) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={true}
    onClose={close}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    style={{
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Fade in={true}>
      <Paper elevation={3} style={{ maxWidth: 600, padding: 30 }}>
        <h3 style={{ marginBottom: 20, textAlign: "center" }}>
          Are you sure you want to delete this {category}?
        </h3>
        <hr style={{ width: "100%" }} />
        {loading && (
          <LinearProgress color="secondary" style={{ width: "100%" }} />
        )}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={close}
            style={{ marginRight: 10, width: "100%" }}
          >
            CLOSE
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={submit}
            style={{ width: "100%" }}
          >
            DELETE
          </Button>
        </div>
      </Paper>
    </Fade>
  </Modal>
);
