export const styles = {
  main: {
    marginTop: 30,
    width: '80%',
  },
  container: {
    padding: 30,
  },
  tableRowHeader: {
    fontWeight: 'bold',
  },
};
