export const styles = {
  main: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
  secondary: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
