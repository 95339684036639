import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";

export const ListingCard = ({ action, listing }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        subheader={"$" + listing.price}
        title={listing.name}
      />
      <CardContent>
        <Typography variant="body2" component="p">
          {listing.description}
        </Typography>
        <Typography variant="body2" component="p">
          {listing.category}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button size="small" onClick={() => action(listing)}>
          View
        </Button>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles({
  root: {
    margin: 5,
    maxWidth: 400,
    width: "100%",
    minWidth: 300,
    borderColor: "#3366FF",
    borderWidth: 1,
  },
  actions: {
    justifyContent: "center",
  },
  buttontext: {
    fontWeight: "bold",
  },
  header: {
    alignItems: "center",
    backgroundColor: "#F4F6FA",
    fontWeight: "bold",
    textAlign: "center",
  },
  title: {
    fontSize: 14,
  },
});
