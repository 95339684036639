import { Button } from '@material-ui/core';
import React, { useState } from 'react';
// Import components
import {
  GenericAddButton,
  GenericSnackbar,
  Header,
  SideBar,
} from '../../Components/Misc';
import { AddRewardForm } from '../../Components/Rewards';
import { addReward } from '../../Helpers/Data';

/* Add a new reward to Firestore. By default, all user's who have notifications working will receive it. */
export const AddReward = ({ history }) => {
  const [message, setMessage] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const projectId = localStorage.getItem('appId');

  /* Save the reward in firebase storage. */
  const showLoadingProcess = (state) => {
    setSubmitting(true);
    callAddReward(state);
  };

  /* Save the reward in firebase storage. */
  const callAddReward = async ({ body, points, title }) => {
    const saved = await addReward(body, parseFloat(points), title);
    const shouldShow = saved === 'SUCCESS';
    const msg = shouldShow ? 'Your reward has been saved!' : saved;
    setSubmitting(false);
    showSnackbar(msg, shouldShow);
  };

  /* Close all modals. */
  const closeModals = () => {
    setSnackbar(false);
  };

  /* Initiate the redirect timer, used for when operation is successful and user needs to go back. */
  const initRedirectTimer = () => {
    setTimeout(() => {
      navigateBack();
    }, 3000);
  };

  /* Send the user to the previous screen. */
  const navigateBack = () => {
    history.push({ pathname: `/rewards/${projectId}` });
  };

  /* Show the snackbar if something goes wrong. */
  const showSnackbar = (msg, redirect) => {
    setMessage(msg);
    setSnackbar(true);
    if (redirect) {
      initRedirectTimer();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <SideBar history={history} projectId={projectId} screen="Rewards" />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Header
          history={history}
          projectId={projectId}
          screen="Manage My Rewards"
        />

        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
            marginTop: 100,
          }}
        >
          <div style={{ marginBottom: 30, paddingLeft: 30, width: '100%' }}>
            <Button
              color="primary"
              onClick={history.goBack}
              variant="contained"
            >
              Back
            </Button>
          </div>

          <AddRewardForm
            error={showSnackbar}
            submit={showLoadingProcess}
            submitting={submitting}
          />

          {/* Show a back button in the top left corner of the page. */}
          <div style={{ position: 'fixed', left: 100, top: 80 }}>
            <GenericAddButton
              action={this.navigateBack}
              screen="rewards"
              text="BACK"
            />
          </div>
          {/* Show modals down here. */}
          {snackbar && (
            <GenericSnackbar
              close={closeModals}
              message={message}
              open={snackbar}
            />
          )}
        </div>
      </div>
    </div>
  );
};
