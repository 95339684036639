/* Used to grab the link from our url and pull the proper firebase project. */
export function getFirebaseUrl() {
  let siteCode = window.location.search;

  if (siteCode.length === 0) {
    siteCode = window.location.pathname.split('$')[1];
    siteCode = '?' + siteCode;
  }

  siteCode = siteCode === '?undefined' ? '?HGrille' : siteCode;
  localStorage.setItem('appId', siteCode);
  return siteCode;
}
